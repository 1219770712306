import React, { useState, useEffect, FormEvent } from 'react'
import User from '../modules/data-objects/user'
import cupidFetch from '../modules/cupid-fetch'
import { useHistory } from 'react-router-dom'
import { LoggedInAs } from './utils/logged-in-as'
import Subject from '../modules/data-objects/subject'

const webFormName = "CPD App"

class Props {
    subject!: Subject;
}

export default function UserDetails({ subject }: Props) {
    const history = useHistory()
    const [user, setUser] = useState<User | null>(null)
    const [waitingForServer, setWaitingForServer] = useState(false)

    const fieldOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedUser = { ...user } as any
        updatedUser[event.target.id] = event.target.value
        setUser(updatedUser)
    }

    useEffect(
        () => {
            let isMounted = true;
                (async () => {
                    setWaitingForServer(true)
                    const result = await cupidFetch<null, User>(`/api/user`)
                    if (result && isMounted) {
                        setWaitingForServer(false)
                        setUser(result)
                    }
                })()
            return () => { isMounted = false }
        },
        []
    )

    const onCloseClick = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setWaitingForServer(true)
        const payload = { ...user!, webFormName }
        const result = await cupidFetch<User, User>(`/api/user`, "PUT", payload)
        setWaitingForServer(false)
        if (!result) return
        history.push("/")
    }

    const renderRow = (attrName: string, label: string, maxLength: number = 100) => {
        const untypedUser = (user as any)
        const webFormAttrName = "webForm" + attrName[0].toUpperCase() + attrName.slice(1)
        return (<>
            <div className="cell">
                <label htmlFor={webFormAttrName}>{label}</label>
            </div>
            <div className="small-6 cell">
                <input disabled type="text" id="middle-label" placeholder={untypedUser?.[attrName]} />
            </div>
            <div className="small-6 cell">
                <input
                    type="text"
                    id={webFormAttrName}
                    value={untypedUser?.[webFormAttrName] ?? ""}
                    placeholder="New value"
                    maxLength={maxLength}
                    onChange={fieldOnChange}
                />
            </div>
        </>)
    }

    return (
        <div className="grid-container main-content">
            <div className="grid-x">
                <div className="cell medium-12">
                    <h1 className="page-title">Update user details</h1>
                    <LoggedInAs subject={subject} />
                </div>
            </div>
            <div className="grid-x">
                <div className="cell medium-7 intro-text">
                    <p>This area is for you to tell us of any changes to your user details, please amend where required in
                    the New value box (only fill in what's changed). Note these are not applied instantly; 
                    we will review them and update your membership record accordingly.</p>
                </div>
                <div className="cell medium-5"></div>
            </div>
            <div className="grid-x">
                <div className="cell medium-12">
                    <div className="callout secondary form-box">
                        <form onSubmit={onCloseClick}>
                            <fieldset disabled={!user || waitingForServer}>
                                <div className="grid-x grid-padding-x">
                                    {renderRow("salutation", "Title", 20)}
                                    {renderRow("firstName", "First name")}
                                    {renderRow("lastName", "Last name")}
                                    {renderRow("addressLine1", "Street address 1")}
                                    {renderRow("addressLine2", "Street address 2")}
                                    {renderRow("addressLine3", "Street address 3")}
                                    {renderRow("city", "City")}
                                    {renderRow("county", "County/State")}
                                    {renderRow("postCode", "Postal code")}
                                    {renderRow("country", "Country")}
                                    {renderRow("jobTitle", "Job title")}
                                    {renderRow("organisation", "Organisation")}
                                    {renderRow("businessPhone", "Business phone")}
                                    {renderRow("mobilePhone", "Mobile phone")}
                                    {renderRow("email", "Email")}
                                    <div className="small-12 cell">
                                        <input type="submit" className="button float-right" value="Save" />
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
