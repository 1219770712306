import React, { useState } from 'react'
import ReviewGrant from '../../modules/data-objects/review-grant'
import ViewGrant from './view-grant'
import EditGrant from './edit-grant'
import { AccordionItem, AccordionTitle, AccordionContent } from 'react-foundation'
import { formatDateWithoutTimeForDisplay } from '../../modules/date-without-time'
import { showAlert } from '../../modules/show-alert'
import CupidSlideDown from '../utils/cupid-slidedown'

class Props {
    reviewGrant!: ReviewGrant
    saveEdit!: (updates: Partial<ReviewGrant>) => void
    deleteGrant!: (reviewGrantId: string | null) => void
    waitingForServer!: boolean
    initiallyExpanded!: boolean
}

export default function ReviewGrantRow({ reviewGrant, saveEdit, deleteGrant, waitingForServer, initiallyExpanded }: Props) {
    const [editing, setEditing] = useState(!reviewGrant.reviewGrantId)
    const [expanded, setExpanded] = useState(initiallyExpanded || !reviewGrant.reviewGrantId)

    const expandButtonClick = () => {
        if (expanded && editing) {
            showAlert("You are currently editing - please save or cancel")
            return;
        }
        setExpanded(o => !o)
    }

    const startEditing = () => setEditing(true)

    const cancelEditing = () => {
        setEditing(false)
        if (!reviewGrant.reviewGrantId) {
            deleteGrant(reviewGrant.reviewGrantId)
        }
    }

    const onSaveEdit = (updates: Partial<ReviewGrant>) => {
        saveEdit(updates)
        setEditing(false)
    }

    return (
        <AccordionItem>
            <AccordionTitle onClick={expandButtonClick}>{
                reviewGrant.reviewGrantId
                    ? <>
                        <span>{reviewGrant.reviewerName}</span>
                        <span> | </span>
                        <span>{formatDateWithoutTimeForDisplay(reviewGrant.expiryDate)}</span>
                    </>
                    : "New reviewer"
            }
            </AccordionTitle>
            <CupidSlideDown>
                {expanded &&
                    <AccordionContent isActive={true}>
                        <div className="grid-x">
                            {editing
                                ? <EditGrant reviewGrant={reviewGrant} saveEdit={onSaveEdit} cancelEdit={cancelEditing} waitingForServer={waitingForServer} />
                                : <ViewGrant reviewGrant={reviewGrant} startEditing={startEditing} deleteGrant={deleteGrant} waitingForServer={waitingForServer} />
                            }
                        </div>
                    </AccordionContent>
                }
            </CupidSlideDown>
        </AccordionItem>
    )
}
