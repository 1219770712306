import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ActivityList from './activity-list';
import { encodeCurrentUrl } from '../../modules/utils';
import ProgressWidget from '../target-progress/progress-widget';
import Subject from '../../modules/data-objects/subject';
import { LoggedInAs } from '../utils/logged-in-as';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import ActivityListYearSelector, { getYearSelection } from './activity-list-year-selector';

class Props {
    subject!: Subject
}

export default function ActivityListPage({ subject }: Props) {
    const history = useHistory()
    const location = useLocation()
    const [nonce, setNonce] = useState(0)
    const { yearSelectorValues, selectedEntry } = getYearSelection(location)

    // Note there is no callback for selecting a different year because that causes the browser location
    // to be updated (and hence the whole page is re-rendered)

    const newButtonClick = () => {
        const returnUrl = encodeCurrentUrl(history);
        history.push(`/activities/new?returnurl=${returnUrl}`);
    }

    const reloadActivityList = () => {
        setNonce(oldValue => oldValue + 1)
    }

    return (
        <>
            <div className="grid-container main-content">
                <div className="grid-x">
                    <div className="cell medium-12">
                        <h1 className="page-title">My CPD</h1>
                        <LoggedInAs subject={subject} />
                    </div>
                </div>
                <div className="grid-x">
                    <div className="cell medium-7 intro-text">
                        <p>Below is your full MyIHEEM CPD record (italics represents CPD in draft status). Click on an activity to expand the details.</p>
                        <p>To add a new piece of CPD activity, click on the New CPD button.</p>
                        <p>The gauges below show your actual points and hours to date against targeted for the selected calendar year.</p>
                        {selectedEntry.year &&
                            <ProgressWidget key={nonce} isSysadmin={subject.isSysadmin()} year={selectedEntry.year} />
                        }
                        <ActivityListYearSelector yearSelectorValues={yearSelectorValues} selectedEntry={selectedEntry} />
                    </div>
                    <div className="cell medium-4 medium-offset-1">
                        <button className="button" onClick={newButtonClick}><FontAwesomeIcon icon={faPlusCircle} size="lg" /> New CPD</button>
                    </div>
                </div>
            </div>

            <ActivityList
                key={selectedEntry.queryString}
                queryString={selectedEntry.queryString}
                reloadActivityListCallback={reloadActivityList}
                subject={subject}
            />
        </>
    )
}

