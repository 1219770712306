import { useEffect, useState } from "react";
import { startCase, isEmpty, pick } from "lodash";
import Subject from "../../modules/data-objects/subject";
import { getSysInfo } from "../../modules/data-objects/sys-info";
import { DateInput } from "../utils/date-input";
import { DateInputOptional } from "../utils/date-input-optional";
import { LoggedInAs } from "../utils/logged-in-as";
import TextareaAutosize from 'react-textarea-autosize';
import { MentorAgreement, MentorRelationship, mentorRelationshipFromJson, newMentorRelationship, otherPersonRole } from "../../modules/data-objects/mentor";
import { ChangeEvent } from "react";
import cupidFetch from "../../modules/cupid-fetch";
import User from "../../modules/data-objects/user";
import { showAlert } from "../../modules/show-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { useHistory, useLocation } from "react-router-dom";

class Props {
    mentorRelationshipId?: string
    subject!: Subject;
}

export default function EditMentorRelationship({ mentorRelationshipId, subject }: Props) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    // note if isNew is false, mentorRelationship.isMentor will be wrong until we have loaded the record
    const isNew = !mentorRelationshipId
    const [mentorRelationship, setMentorRelationship] = useState(
        newMentorRelationship(
            isNew && searchParams.get("ismentor")?.toLowerCase?.() === 'true'
        )
    )
    const otherPerson = otherPersonRole(mentorRelationship)
    const [userSearch, setUserSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const [hasMentorAgreement, setHasMentorAgreement] = useState(false)
    const history = useHistory()
    const [mentorAgreementFile, setMentorAgreementFile] = useState<File | undefined>(undefined)

    useEffect(() => {
        const fetchMentorRelationship = async () => {
            if (mentorRelationshipId) {
                setLoading(true)
                const mentorAgreements = await cupidFetch<null, MentorAgreement[]>(`/api/mentorrelationships/${mentorRelationshipId}/mentoragreements`)
                if (!mentorAgreements) return
                setHasMentorAgreement(!!mentorAgreements.length)
                const mentorRelationship = await cupidFetch<null, MentorRelationship>(`/api/mentorrelationships/${mentorRelationshipId}`)
                setLoading(false)
                if (!mentorRelationship) return
                setMentorRelationship(mentorRelationshipFromJson(mentorRelationship))
            }
        }
        fetchMentorRelationship()
    }, [mentorRelationshipId])

    const mentorRelationshipTypes = [
        { mentorRelationshipTypeId: "", title: "" },
        ...getSysInfo().mentorRelationshipTypes,
    ]

    const verifyUser = async () => {
        setLoading(true)
        const user = await cupidFetch<null, User>(`/api/user/getbyemailormembershipno?emailormembershipno=${encodeURIComponent(userSearch)}`)
        setLoading(false)
        if (!user) return
        if (isEmpty(user)) {
            await showAlert(`No match found for user "${userSearch}"`)
        } else {
            setUserSearch("")
            setMentorRelationship(x => ({
                ...x,
                ...pick(user, ["email", "displayName", "userId", "membershipNo"])
            }))
        }
    }

    const goBack = () => {
        history.push('/mentorrelationships')
    }

    const saveClick = async () => {
        setLoading(true)
        try {
            const urlSuffix = isNew ? "" : `/${mentorRelationship.mentorRelationshipId}`
            const savedMentorRelationship = await cupidFetch<MentorRelationship, MentorRelationship>(
                `/api/mentorrelationships${urlSuffix}`,
                isNew ? "POST" : "PUT",
                mentorRelationship
            )
            if (!savedMentorRelationship) return;
            if (mentorAgreementFile) {
                const url = `/api/mentorrelationships/${savedMentorRelationship!.mentorRelationshipId}/mentoragreements`
                const formData = new FormData();
                formData.append("file", mentorAgreementFile)
                const savedMentorAgreement = await cupidFetch<FormData, MentorAgreement>(url, "POST", formData)
                if (!savedMentorAgreement) return;
            }
            goBack()
        } finally {
            setLoading(false)
        }
    }

    const startDateChange = (startDate: string) =>
        setMentorRelationship(x => ({ ...x, startDate }))

    const endDateChange = (endDate: string | null) =>
        setMentorRelationship(x => ({ ...x, endDate }))

    const relationshipTypeChange = (e: ChangeEvent<HTMLSelectElement>) =>
        setMentorRelationship(x => ({ ...x, relationshipTypeId: e.target.value || null }))

    const notesChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
        setMentorRelationship(x => ({ ...x, notes: e.target.value }))

    const canSave = mentorRelationship.userId && mentorRelationship.relationshipTypeId

    return (
        <div className="grid-container main-content">
            <div className="grid-x">
                <div className="cell medium-12">
                    <h1 className="page-title">
                        {isNew ? `Add ${otherPerson}` : "Edit mentor relationship"}
                    </h1>
                    <LoggedInAs subject={subject} />
                </div>
            </div>
            <div className="grid-x">
                <div className="cell medium-7">
                    <div className="callout secondary form-box">
                        <fieldset disabled={loading}>
                            {isNew && <div className="cell">
                                <div>
                                    <label htmlFor="eventSessionCode">Enter full email address or IHEEM membership No. of {otherPerson}</label>
                                </div>
                                <div className="input-group">
                                    <input type="text" className="input-group-field" value={userSearch} maxLength={256} onChange={e => setUserSearch(e.target.value)} />
                                    <div className="input-group-button">
                                        <button disabled={!userSearch} className="button" onClick={verifyUser}>Verify</button>
                                    </div>
                                </div>
                            </div>}
                            {mentorRelationship.userId && <div>
                                {!isNew &&
                                    <p><strong>{startCase(otherPerson)} details:</strong></p>
                                }
                                <p><strong>Full name:</strong> {mentorRelationship.displayName}</p>
                                <p><strong>Email:</strong> {mentorRelationship.email}</p>
                                <p><strong>Membership No:</strong> {mentorRelationship.membershipNo}</p>
                            </div>}
                            <div className="small-12 cell">
                                <label htmlFor="mentorRelationshipTypeId">Mentor relationship type</label>
                                <select disabled={!!mentorRelationshipId} id="mentorRelationshipTypeId"
                                    value={mentorRelationship.relationshipTypeId || ""}
                                    onChange={relationshipTypeChange}>
                                    {
                                        mentorRelationshipTypes.map(x =>
                                            <option key={x.mentorRelationshipTypeId} value={x.mentorRelationshipTypeId}>{x.title}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="small-12 cell">
                                <label htmlFor="startDate">Start date</label>
                                <DateInput disabled={!!mentorRelationshipId} id="startDate" value={mentorRelationship.startDate} onChange={startDateChange} />
                            </div>
                            <div className="small-12 cell">
                                <label htmlFor="endDate">End date</label>
                                <DateInputOptional id="endDate" value={mentorRelationship.endDate} onChange={endDateChange} />
                            </div>
                            <div className="small-12 cell">
                                <label htmlFor="notes">Notes</label>
                                <TextareaAutosize id="expectedLearning"
                                    maxLength={1048576}
                                    value={mentorRelationship.notes}
                                    onChange={notesChange} />
                            </div>
                            {!loading && !hasMentorAgreement &&
                                <div className="small-12 cell">
                                    <label htmlFor="fileUpload" className="button">Mentor agreement (pdf)</label>
                                    <input
                                        type="file"
                                        id="fileUpload"
                                        name="file"
                                        className="show-for-sr"
                                        accept=".pdf"
                                        onChange={x => setMentorAgreementFile(x.target.files?.[0])
                                        }
                                    />
                                    {!!mentorAgreementFile && <>
                                        <span> {mentorAgreementFile.name} </span>
                                        <button type="button" className="button" aria-label="Dismiss alert"
                                            onClick={() => setMentorAgreementFile(undefined)}>
                                            clear
                                        </button>
                                    </>}
                                </div>
                            }
                            <div className="small-12 cell">
                                <div className="small button-group float-right">
                                    <button type="button" disabled={loading || !canSave} onClick={saveClick} className="button">
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                        <span> Save</span>
                                    </button>
                                    <button type="button" disabled={loading} className="button alert" onClick={goBack}>Cancel</button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div >
        </div >
    )
}
