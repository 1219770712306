import React, { useState, FormEvent } from 'react'
import ReviewGrant from '../../modules/data-objects/review-grant'
import { DateInput } from '../utils/date-input'

class Props {
    reviewGrant!: ReviewGrant
    cancelEdit!: () => void
    saveEdit!: (updates: Partial<ReviewGrant>) => void
    waitingForServer!: boolean
}

/*
 * for the date fields, we don't update the state in the change handler unless they are valid, but
 * for the email this makes no sense because you can't start typing an email address as it is 
 * initially invalid, so we update the state even if the address is invalid. However, the form
 * won't submit if it's invalid (and the browser will automatically show the validation error).
 */
export default function EditGrant({ reviewGrant, cancelEdit, saveEdit, waitingForServer }: Props) {
    const [reviewerEmail, setReviewerEmail] = useState(reviewGrant.reviewerEmail)
    const [reviewerName, setReviewerName] = useState(reviewGrant.reviewerName)
    const [expiryDate, setexpiryDate] = useState(reviewGrant.expiryDate)
    const [activityStartDate, setActivityStartDate] = useState(reviewGrant.activityStartDate)
    const [activityEndDate, setActivityEndDate] = useState(reviewGrant.activityEndDate)
    const key = reviewGrant.reviewGrantId

    async function onSubmit(event: FormEvent) {
        event.preventDefault()
        const form = event.target as HTMLFormElement
        if (!form.checkValidity()) return   // this line only needed for unit testing due to https://github.com/jsdom/jsdom/issues/2898
        const updated = {
            reviewGrantId: reviewGrant.reviewGrantId,
            reviewerEmail, reviewerName, expiryDate, activityStartDate, activityEndDate
        }
        saveEdit(updated)
    }

    return (
        <div className="cell medium-6">
            <div className="callout secondary form-box">
                <form onSubmit={onSubmit}>
                    <div className="grid-x grid-padding-x">
                        <div className=" cell">
                            <label htmlFor={`name-${key}`}>Name</label>
                        </div>
                        <div className="small-12 cell">
                            <input
                                type="text"
                                id={`name-${key}`}
                                required={true}
                                value={reviewerName}
                                maxLength={256}
                                onChange={e => setReviewerName(e.target.value)}
                            />
                        </div>
                        <div className=" cell">
                            <label htmlFor={`email-${key}`}>Email</label>
                        </div>
                        <div className="small-12 cell">
                            <input
                                type="email"
                                id={`email-${key}`}
                                required={true}
                                value={reviewerEmail}
                                maxLength={256}
                                onChange={e => setReviewerEmail(e.target.value)}
                            />
                        </div>
                        <div className="cell">
                            <label htmlFor={`expiryDate-${key}`}>Expires on</label>
                        </div>
                        <div className="small-12 cell">
                            <DateInput placeholder="Expires" id={`expiryDate-${key}`} value={expiryDate} onChange={setexpiryDate} />
                        </div>
                        <div className="cell">
                            <label htmlFor={`activityStartDate-${key}`}>Activity from</label>
                        </div>
                        <div className="small-12 cell">
                            <DateInput placeholder="From" id={`activityStartDate-${key}`} value={activityStartDate} onChange={setActivityStartDate} />
                        </div>
                        <div className="cell">
                            <label htmlFor={`activityEndDate-${key}`}>Activity to</label>
                        </div>
                        <div className="small-12 cell">
                            <DateInput placeholder="To" id={`activityEndDate-${key}`} value={activityEndDate} onChange={setActivityEndDate} />
                        </div>
                        <div className="small-12 cell">
                            <div className="small button-group float-right">
                                <fieldset disabled={waitingForServer}>
                                    <button type="submit" className="button">Save</button>
                                    <button type="button" className="button alert" onClick={cancelEdit}>Cancel</button>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
