import { useEffect, useState } from "react";
import Subject from "../../modules/data-objects/subject";
import { MentorAgreement } from "../../modules/data-objects/mentor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { reformatFileName } from "../edit-cpd/attachment-list";
import cupidFetch from "../../modules/cupid-fetch";

class Props {
    mentorRelationshipId!: string
    subject!: Subject;
}

export const MentorAgreementDisplay = ({ mentorRelationshipId, subject }: Props) => {
    const [mentorAgreement, setMentorAgreement] = useState<MentorAgreement | null>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchMentorAgreement = async () => {
            setLoading(true)
            const result = await cupidFetch<null, MentorAgreement[]>(`/api/mentorrelationships/${mentorRelationshipId}/mentoragreements`)
            if (!result) return
            setMentorAgreement(result[0])
            setLoading(false)
        }
        fetchMentorAgreement()
    }, [mentorRelationshipId])

    return <>
        <h5>Mentoring agreement</h5>
        <ul className="resources-list">
            <li>
                {loading
                    ? <>(loading...)</>
                    : mentorAgreement
                        ? <a href={`/api/mentorrelationships/${mentorRelationshipId}/mentoragreements/${mentorAgreement.mentorAgreementId}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faCloudDownloadAlt} />
                            <span> </span>
                            {reformatFileName(mentorAgreement.fileName)}
                        </a>
                        : <>No mentoring agreement</>
                }
            </li>
        </ul>
    </>

}