import { format, parse } from "date-fns"
import getCurrentDate from "./current-date";

/*
 * Dates without a time are a completely different concept
 * from dates as a point in time - consider 2 people born on opposite sides of the world
 * at the same moment. although their birth as a point in time is the same, and their
 * age in seconds will always be the same, their birthday as shown in their passport
 * will be different, no matter where in the world they go.
 * 
 * Some dates in the system such as activity date are the same concept as birthday,
 * if we try to manipulate such dates as timestamps, we wil get messed around by automatic
 * timezone conversions when passing the date between client, server, and database, so
 * the safest thing to do is just store them as strings
 */

export const displayDateFormat = "d MMM yyyy";
const internalDateFormat = "yyyy-MM-dd"

export const formatDateWithoutTimeForDisplay = (date: string | null): string =>
    date ? format(parseDateWithoutTime(date)!, displayDateFormat) : ""

export const getCurrentDateWithoutTime = (): string => formatDateWithoutTime(getCurrentDate());

export const startOfYear = (year: number) => `${year}-01-01`
export const endOfYear = (year: number) => `${year}-12-31`

/*
 * these 2 functions are not exported - you should not be trying to convert between
 * date without time and js Date because of the problems noted above
 */
const formatDateWithoutTime = (date: Date): string => format((date), internalDateFormat);
const parseDateWithoutTime = (s: string): Date => parse(s, internalDateFormat, new Date())
