import Subject from "../../modules/data-objects/subject";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import * as H from 'history';
import { redirectToLoginPage, getReturnUrl } from "./app";
import { AppConfig } from "./app-config";
import Home from "./home";
import EditCpd from "../edit-cpd/edit-cpd";
import ActivityListPage from "../activity-list/activity-list-page";
import ReviewerActivityList from "../activity-list/reviewer-activity-list";
import MonitorActivityList from "../activity-list/monitor-activity-list";
import ReviewGrantPage from "../review-grant/review-grant-page";
import Targets from "../targets";
import ImportCpd from "../import-cpd";
import Pdp from "../pdp";
import UserDetails from "../user-details";
import AddOrEditCpd from "../add-or-edit-cpd";
import Mentoring from "../mentoring/mentoring";
import EditMentorRelationship from "../mentoring/edit-mentor-relationship";
import MentorActivities from "../mentoring/mentor-activities";
import EditMentorActivity from "../mentoring/edit-mentor-activity";

class idParam {
    id?: string;
}

class twoIdParam {
    id1?: string;
    id2?: string;
}

export const appConfigCpd: AppConfig = {
    appDescription: "MyIHEEM CPD",
    renderNonLayoutRoutes: (subject: Subject, history: H.History<unknown>) => [
        <Route
            key={1}
            path={["/activities/:id/edit", "/activities/new"]}
            render={
                (routeProps: RouteComponentProps<idParam>) =>
                    !subject.isAuthenticated()
                        ? redirectToLoginPage(history)
                        : <EditCpd
                            subject={subject}
                            activityId={routeProps.match.params.id}
                            returnUrl={getReturnUrl(history)}
                        />
            } />,
        <Route key={2} path={"/session/:eventsessioncode"} render={routeProps =>
            !subject.isAuthenticated()
                ? redirectToLoginPage(history)
                : <AddOrEditCpd eventSessionCode={routeProps.match.params.eventsessioncode} />
        } />,
        <Route
            key={3}
            path={["/mentorrelationships/:id/edit", "/mentorrelationships/new"]}
            render={
                (routeProps: RouteComponentProps<idParam>) =>
                    !subject.isAuthenticated()
                        ? redirectToLoginPage(history)
                        : <EditMentorRelationship
                            subject={subject}
                            mentorRelationshipId={routeProps.match.params.id}
                        />
            } />,
        <Route
            key={4}
            path={["/mentorrelationships/:id1/activities/:id2/edit", "/mentorrelationships/:id1/activities/new"]}
            render={
                (routeProps: RouteComponentProps<twoIdParam>) =>
                    !subject.isAuthenticated()
                        ? redirectToLoginPage(history)
                        : <EditMentorActivity
                            subject={subject}
                            mentorRelationshipId={routeProps.match.params.id1!}
                            mentorActivityId={routeProps.match.params.id2!}
                        />
            } />,
    ],
    renderLayoutRoutes: (subject: Subject) => {
        if (subject.isReviewer()) {
            return (
                <Route>
                    <ReviewerActivityList subject={subject} />
                </Route>
            )
        }
        if (subject.isMonitor()) {
            return (
                <Route>
                    <MonitorActivityList subject={subject} />
                </Route>)
        }
        return (
            <Switch>
                <Route path="/activities">
                    <ActivityListPage subject={subject} />
                </Route>
                <Route path="/reviewgrants">
                    <ReviewGrantPage subject={subject} />
                </Route>
                <Route path="/targets">
                    <Targets subject={subject} />
                </Route>
                <Route path="/mentorrelationships/:id/activities"
                    render={
                        (routeProps: RouteComponentProps<idParam>) =>
                            <MentorActivities
                                subject={subject}
                                mentorRelationshipId={routeProps.match.params.id!}
                            />
                    } />
                <Route path="/mentorrelationships">
                    <Mentoring subject={subject} />
                </Route>
                <Route path="/user-details">
                    <UserDetails subject={subject} />
                </Route>
                <Route path="/import">
                    <ImportCpd subject={subject} />
                </Route>
                <Route path="/pdp">
                    <Pdp subject={subject} />
                </Route>
                <Route path="/">
                    <Home subject={subject} />
                </Route>
            </Switch>)
    },
    getNavEntries: (subject: Subject) => subject.isReviewerOrMonitor()
        ? []
        : [
            { url: "/", text: "Home" },
            { url: "/activities", text: "My CPD" },
            { url: "/reviewgrants", text: "Grant reviewer access" },
            { url: "/targets", text: "Set targets" },
            { url: "/mentorrelationships", text: "Mentoring" },
            { url: "/import", text: "Import CPD" },
            //            { url: "/pdp", text: "PDP" },
            { url: "/user-details", text: "Update user details" },
        ]
}
