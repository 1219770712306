import { endOfYear, startOfYear } from "../date-without-time"
import getCurrentDate from "../current-date"

export default class ReviewGrant {
    reviewGrantId: string | null = null
    accessToken: string | null = null
    userId: string | null = null
    expiryDate!: string
    activityStartDate!: string
    activityEndDate!: string
    reviewerName: string = ""
    reviewerEmail: string = ""
}

export function reviewGrantFromJson(reviewGrant: ReviewGrant) : ReviewGrant {
    return {
        ...new ReviewGrant(),
        ...reviewGrant,
    }
}

export function newReviewGrant(reviewGrantId: string | null): ReviewGrant {
    const year = getCurrentDate().getFullYear()
    return {
        ...new ReviewGrant(),
        reviewGrantId,
        expiryDate: endOfYear(year),
        activityStartDate: startOfYear(year),
        activityEndDate: endOfYear(year),
    }
}
