import React from 'react'
import { differenceInDays } from "date-fns"
import getCurrentDate from '../../modules/current-date'
import GaugeChart from 'react-gauge-chart'

class Props {
    target!: number | null
    actual!: number
    title!: string
    year!: number
}

export const gaugeWouldRender = (target: number | null, actual: number) => !!(target || actual)

export default function ProgressGauge({ target, actual, title, year }: Props) {
    const yearStart = new Date(year, 0, 1);
    const yearEnd = new Date(year, 11, 31);
    const totalDaysInYear = differenceInDays(yearEnd, yearStart) + 1
    const daysSoFar = differenceInDays(getCurrentDate(), yearStart) + 1

    return (<>
        {gaugeWouldRender(target, actual) &&
            <div style={{ border: "1px solid black", width: "200px", display: "inline-block" }}>
                <div style={{ textAlign: "center" }}>{title} target: {target ?? "n/a"}; actual: {actual}</div>
                <GaugeChart id={`gauge-chart-${title}`}
                    percent={Math.min(target ? actual / target : 0, 1.04)}
                    arcsLength={[daysSoFar / totalDaysInYear, 1 - daysSoFar / totalDaysInYear]}
                    colors={["red", "lightgreen"]}
                    textColor="black"
                    needleColor="lightblue"
                    formatTextValue={() => target ? (actual / target * 100).toFixed(0) + "%" : "no target"}
                />
                <div style={{ float: "left", marginTop: "-15px", marginLeft: "10px" }}>Jan {year}</div>
                <div style={{ float: "right", marginTop: "-15px", marginRight: "10px" }}>Dec {year}</div>
                <div style={{ clear: "both" }}></div>
            </div>}
    </>)
}
