import React from 'react'

/*
 * having the date optional affects the types of the inputs and outputs so this is a separate component
 * from DateInput
 */

class Props {
    value?: string | null
    onChange!: (value: string | null) => void
    form?: string
    id?: string
    placeholder?: string
}

export function DateInputOptional({ value, onChange, form, placeholder, id }: Props) {

    function dateOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.validity.valid) {
            onChange(event.target.value || null);
        }
    }

    return <input type="date"
        id={id}
        form={form}
        placeholder={placeholder}
        value={value || ""}
        min="1900-01-01"
        max="2099-12-31"
        onChange={dateOnChange}
    />
}
