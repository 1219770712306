import { getGlobal, deleteGlobal } from './globals';

export type ShowAlertType = (message: string) => Promise<void>;
export type ShowConfirmType = (message: string) => Promise<boolean>;

const globalName = "showAlert";

function windowConfirm(message: string) {
    return Promise.resolve(window.confirm(message))
}

function windowAlert(message: string) {
    return Promise.resolve(window.alert(message))
}

class AlertGlobals {
    showAlert: ShowAlertType = windowAlert;
    showConfirm: ShowConfirmType = windowConfirm;
}

export function getAlertGlobals(): AlertGlobals {
    return getGlobal<AlertGlobals>(AlertGlobals, globalName);
}

export function resetAlertGlobals(): void {
    deleteGlobal(globalName);
}

export function showAlert(message: string): Promise<void> {
    return getAlertGlobals().showAlert(message);
}

export function showConfirm(message: string): Promise<boolean> {
    return getAlertGlobals().showConfirm(message);
}
