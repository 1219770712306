/**
 * a module which stores global variables. each is expected to be an object 
 * and is identified by a name.
 * if you ask for one that doesn't exist, it constructs it and stores it
 * before returning. You can then update the properties
 * Really this is intended as a dependency injection mechanism only used
 * for constructing test doubles. the idea is that the default constructor
 * for the class should set the production values, but the test runner can
 * override them if required.
 */
let _globals: any = {};

export function deleteGlobal(name:string) {
    delete _globals[name];
}

export function clearAllGlobals() {
    _globals = {};
}

export function getGlobal<T>(type: { new(): T }, name: string): T {
    /*
     * this code inspired by https://stackoverflow.com/questions/17382143
     * I've no idea how it works
     */
    if (_globals[name] === undefined) {
        _globals[name] = new type();
    }
    return _globals[name] as T;
}
