import { useHistory } from "react-router-dom"
import { Accordion, AccordionItem, AccordionTitle } from "react-foundation"
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MentorRelationshipRow from "./mentor-relationship-row"
import { MentorRelationship } from "../../modules/data-objects/mentor"
import Subject from "../../modules/data-objects/subject"

class Props {
    subject!: Subject;
    mentorRelationships!: MentorRelationship[]
    loading!: boolean
    isMentor!: boolean
}

export default function MentorRelationshipView({ subject, mentorRelationships, loading, isMentor }: Props) {
    const history = useHistory()

    const addRelationship = () => {
        history.push(`/mentorrelationships/new?ismentor=${isMentor}`)
    }

    return <>
        <div className="grid-container">
            <h3>{isMentor ? "People you are mentoring" : "People who are mentoring you"}</h3>
            <Accordion>
                {loading &&
                    <AccordionItem>
                        <AccordionTitle>Loading...</AccordionTitle>
                    </AccordionItem>}
                {mentorRelationships.map(mentorRelationship =>
                    <MentorRelationshipRow
                        key={mentorRelationship.mentorRelationshipId}
                        subject={subject}
                        mentorRelationship={mentorRelationship} />
                )}
                {!(loading || mentorRelationships.length) &&
                    <AccordionItem>
                        <AccordionTitle>No mentor relationships</AccordionTitle>
                    </AccordionItem>}
            </Accordion>
        </div>
        <div className="grid-container">
            <div className="cell medium-4">
                <button className="button" onClick={addRelationship}>
                    <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                    <span> </span>
                    <span>{isMentor ? "Add mentee" : "Add mentor"}</span>
                </button>
            </div>
        </div>
    </>
}
