import { useEffect, useState } from "react";
import Subject from "../../modules/data-objects/subject";
import { DateInput } from "../utils/date-input";
import { LoggedInAs } from "../utils/logged-in-as";
import TextareaAutosize from 'react-textarea-autosize';
import { MentorActivity, MentorRelationship, mentorActivityFromJson, myRole, newMentorActivity } from "../../modules/data-objects/mentor";
import { ChangeEvent } from "react";
import cupidFetch from "../../modules/cupid-fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";
import { nanToNull } from "../../modules/utils";
import { newActivity } from "../../modules/data-objects/activity";
import { getSysInfo } from "../../modules/data-objects/sys-info";

class Props {
    mentorRelationshipId?: string
    mentorActivityId?: string
    subject!: Subject;
}

export default function EditMentorActivity({ mentorRelationshipId, mentorActivityId, subject }: Props) {
    // note if isNew is false, mentorActivity.isMentor will be wrong until we have loaded the record
    const isNew = !mentorActivityId
    const [mentorActivity, setMentorActivity] = useState(newMentorActivity())
    const [addToCpd, setAddToCpd] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        const fetchMentorActivity = async () => {
            if (mentorActivityId) {
                setLoading(true)
                const mentorActivity = await cupidFetch<null, MentorActivity>(`/api/mentorrelationships/${mentorRelationshipId!}/activities/${mentorActivityId!}`)
                setLoading(false)
                if (!mentorActivity) return
                setMentorActivity(mentorActivityFromJson(mentorActivity))
            }
        }
        fetchMentorActivity()
    }, [mentorRelationshipId, mentorActivityId])

    const goBack = () => {
        history.push(`/mentorrelationships/${mentorRelationshipId}/activities`)
    }

    const saveClick = async () => {
        const urlSuffix = isNew ? "" : `/${mentorActivity.mentorActivityId}`
        setLoading(true)
        const result = await cupidFetch<MentorActivity, null>(
            `/api/mentorrelationships/${mentorRelationshipId}/activities${urlSuffix}`,
            isNew ? "POST" : "PUT",
            mentorActivity
        )
        if (!result) return
        if (addToCpd) {
            // we need to fetch the mentor relationship to find out what the activity type should me - mentor or mentee
            const mentorRelationship = await cupidFetch<null, MentorRelationship>(`/api/mentorrelationships/${mentorRelationshipId}`)
            if (!mentorRelationship) return;
            const activity = newActivity(null)
            activity.title = mentorActivity.title
            activity.activityDate = mentorActivity.activityDate
            activity.activityTypeId = getSysInfo()
                .activityTypes
                .find(x => x.title === `Mentoring (${myRole(mentorRelationship)})`)!
                .activityTypeId
            activity.comments = mentorActivity.notes
            const result = await cupidFetch('/api/activities', "POST", activity)
            if (!result) return;
        }
        setLoading(false)
        goBack()
    }

    const titleChange = (e: ChangeEvent<HTMLInputElement>) =>
        setMentorActivity(x => ({ ...x, title: e.target.value }))

    const activityDateChange = (activityDate: string) =>
        setMentorActivity(x => ({ ...x, activityDate }))

    const notesChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
        setMentorActivity(x => ({ ...x, notes: e.target.value }))

    const hoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.validity.valid) {
            setMentorActivity(x => ({ ...x, hours: nanToNull(parseFloat(e.target.value)) }))
        }
    }

    const addToCpdChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAddToCpd(x => !x)
    }

    const canSave = mentorActivity.title && mentorActivity.hours

    return (
        <div className="grid-container main-content">
            <div className="grid-x">
                <div className="cell medium-12">
                    <h1 className="page-title">
                        {isNew ? "Add" : "Edit"} mentoring activity
                    </h1>
                    <LoggedInAs subject={subject} />
                </div>
            </div>
            <div className="grid-x">
                <div className="cell medium-7">
                    <div className="callout secondary form-box">
                        <fieldset disabled={loading}>
                            <div className="small-12 cell">
                                <label htmlFor="title">Title</label>
                                <input type="text" disabled={!!mentorActivityId} id="title" value={mentorActivity.title} maxLength={256} onChange={titleChange} />
                            </div>
                            <div className="small-12 cell">
                                <label htmlFor="date">Activity date</label>
                                <DateInput id="date" disabled={!!mentorActivityId} value={mentorActivity.activityDate} onChange={activityDateChange} />
                            </div>
                            <div className="small-12 cell">
                                <label htmlFor="hours">Hours</label>
                                <input type="number" disabled={!!mentorActivityId} step="0.5" min="0" max={1000000} id="hours" value={mentorActivity.hours ?? ""} onChange={hoursChange} />
                            </div>
                            <div className="small-12 cell">
                                <label htmlFor="notes">Notes</label>
                                <TextareaAutosize id="notes"
                                    maxLength={1048576}
                                    value={mentorActivity.notes}
                                    onChange={notesChange} />
                            </div>
                            {!mentorActivityId && <div className="small-12 cell">
                                <input id="addToCpd" type="checkbox" checked={addToCpd} onChange={addToCpdChange} />
                                <label htmlFor="addToCpd">Add to CPD?</label>
                            </div>}
                            <div className="small-12 cell">
                                <div className="small button-group float-right">
                                    <button type="button" disabled={loading || !canSave} onClick={saveClick} className="button">
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                        <span> Save</span>
                                    </button>
                                    <button type="button" disabled={loading} className="button alert" onClick={goBack}>Cancel</button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    )
}
