import { useLocation } from 'react-router-dom';
import ActivityList from './activity-list';
import ProgressWidget from '../target-progress/progress-widget';
import Subject from '../../modules/data-objects/subject';
import { LoggedInAs } from '../utils/logged-in-as';
import { reviewGrantFromJson } from '../../modules/data-objects/review-grant';
import ActivityListYearSelector, { getYearSelection } from './activity-list-year-selector';

class Props {
    subject!: Subject
}

function MonitorActivityList({ subject }: Props) {
    const location = useLocation()
    const authenticated = subject.authenticated!
    const reviewer = authenticated.reviewer!
    const reviewGrant = reviewGrantFromJson(reviewer.reviewGrant)
    const { yearSelectorValues, selectedEntry } = getYearSelection(location)

    return (
        <>
            <div className="grid-container main-content">
                <div className="grid-x">
                    <div className="cell medium-12">
                        <h1 className="page-title">
                            <span>View CPD for </span>
                            {reviewer.revieweeName}
                            <br />
                            <span>(Membership No. </span>
                            <strong>{reviewer.revieweeMembershipNo}</strong>
                            <span>)</span>
                        </h1>
                        <LoggedInAs subject={subject} />
                    </div>
                </div>
                <div className="grid-x">
                    <div className="cell medium-7 intro-text">
                        {selectedEntry.year &&
                            <ProgressWidget year={selectedEntry.year} userId={reviewGrant.userId!} />
                        }
                        <ActivityListYearSelector yearSelectorValues={yearSelectorValues} selectedEntry={selectedEntry} />
                    </div>
                    <div className="cell medium-4 medium-offset-1" />
                </div>
            </div>
            <ActivityList
                key={selectedEntry.queryString}
                queryString={`${selectedEntry.queryString}&userid=${reviewGrant.userId}`}
                reloadActivityListCallback={() => { }}
                subject={subject}
            />
        </>
    )
}

export default MonitorActivityList;
