import React, { useState, useEffect } from 'react';
import cupidFetch from "../../modules/cupid-fetch";
import { Link } from 'react-router-dom';
import Subject from '../../modules/data-objects/subject';
import Logo from '../../IHEEM-logo.png'
import useReactSimpleMatchMedia from 'react-simple-matchmedia'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons'
import { NavEntry } from './app-config';

class Props {
    loginCallback!: () => void;
    subject!: Subject;
    getNavEntries!: (subject:Subject) => NavEntry[]
}

enum MenuState {
    Small,          // window width < 640px, nav is hidden, menu button shown
    SmallWithMenu,  // window width < 640px, nav is shown, menu button shown
    NotSmall        // window width >= 640px nav is shown, menu button shown hidden
}
/*
 * We say "NotSmall" as opposed to "Large" to be consistent with Foundation terminology (it means medium or large)
 * initial state is Small or NotSmall
 * transitions between the states are:
 *   Small => SmallWithMenu - user clicks menu button
 *   SmallWithMenu => Small - user clicks menu button or selects an item from the nav
 *   Small or SmallWithMenu => NotSmall - user makes window bigger
 *   NotSmall => Small - user makes window smaller
 *
 * Note the 640 is the default value in Foundation. If that gets changed, you'll need to change it here as well
 */

function Nav({ loginCallback, subject, getNavEntries }: Props) {
    const smallScreen: boolean = !useReactSimpleMatchMedia('(min-width: 640px)');
    const [menuState, setMenuState] = useState(smallScreen ? MenuState.Small : MenuState.NotSmall)

    // detect window changing size
    useEffect(
        () => {
            if (smallScreen && menuState === MenuState.NotSmall) {
                setMenuState(MenuState.Small)
            }
            if (!smallScreen && menuState !== MenuState.NotSmall) {
                setMenuState(MenuState.NotSmall)
            }
        },
        [smallScreen, menuState]
    )

    const logout = async () => {
        const response = await cupidFetch("/api/session", "DELETE", null);
        if (response) {
            loginCallback()
        }
    }

    const onMenuButtonClick = () => {
        setMenuState(menuState === MenuState.Small ? MenuState.SmallWithMenu : MenuState.Small)
    }

    const hideMenu = () => {
        if (menuState === MenuState.SmallWithMenu) {
            setMenuState(MenuState.Small)
        }
    }

    return (
        <>
            {menuState !== MenuState.NotSmall &&
                <div className="title-bar">
                    <button data-testid="menuButton" className="menu-icon" type="button" onClick={onMenuButtonClick}></button>
                    <div className="title-bar-title">Menu</div>
                </div>
            }
            <div className="top-wrap">
                <div className="top-bar grid-container">
                    <div className="media-object">
                        <div className="media-object-section">
                            <div className="thumbnail">
                                <img src={Logo} alt="IHEEM Logo" />
                            </div>
                        </div>
                    </div>
                    {menuState !== MenuState.Small &&
                        <div className="top-bar-left">
                            <ul className="vertical medium-horizontal menu">
                                {getNavEntries(subject).map((o, i) =>
                                    <li key={i}>
                                        <Link to={o.url} onClick={hideMenu}>{o.text}</Link>
                                    </li>
                                )}
                                <li>
                                    <a data-testid="logoutButton" href="#/" onClick={logout}><FontAwesomeIcon icon={faUnlockAlt} /> Logout</a>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default Nav;
