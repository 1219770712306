import ReviewGrant from "./review-grant"

export const ROLE_NOT_AUTHENTICATED = "NOT_AUTHENTICATED"
export const ROLE_CPDUSER = "CPDUSER"
export const ROLE_REVIEWER = "REVIEWER"
export const ROLE_MONITOR = "MONITOR"
export const ROLE_SYSADMIN = "SYSADMIN"

export class SubjectDto {
    role?: string | null
    authenticated?: AuthenticatedUser | null
}

export default class Subject {
    role!: string
    authenticated?: AuthenticatedUser | null

    constructor(obj: SubjectDto) {
        if (!obj || !obj.role) {
            throw new Error("You can't construct a subject with no role")
        }
        Object.assign(this, obj)
    }

    isAuthenticated() {
        return this.role !== ROLE_NOT_AUTHENTICATED
    }

    isCpdUser() {
        return this.role === ROLE_CPDUSER
    }

    isSysadmin() {
        return this.role === ROLE_SYSADMIN
    }

    isReviewer() {
        return this.role === ROLE_REVIEWER
    }

    isMonitor() {
        return this.role === ROLE_MONITOR
    }

    isReviewerOrMonitor() {
        return this.isReviewer() || this.isMonitor()
    }
}

export class AuthenticatedUser {
    displayName?: string | null
    emailAddress?: string | null
    membershipNo?: string | null
    reviewer?: Reviewer | null
}

export class Reviewer {
    reviewGrant!: ReviewGrant
    revieweeName!: string
    revieweeMembershipNo!: string
}
