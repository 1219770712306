import React from 'react'
import Subject from '../../modules/data-objects/subject'

class Props {
    subject!: Subject
}

export function LoggedInAs({ subject }: Props) {
    const authenticated = subject.authenticated!

    const getDescription = () => {
        switch (true) {
            case subject.isCpdUser(): return (
                <span>Membership No. <strong>{authenticated.membershipNo || "n/a"}</strong></span>
            )
            case subject.isSysadmin(): return (
                <span>(Sysadmin)</span>
            )
            case subject.isReviewer(): return (
                <span>Email <strong>{authenticated.emailAddress}</strong></span>
            )
            case subject.isMonitor(): return (
                <span><strong>View mode only</strong></span>
            )
        }
    }

    return (
        <p className="member-info ">
            <span>You are logged in as <strong>{authenticated.displayName}</strong></span>
            <span> | </span>
            <span>{getDescription()}</span>
        </p>
    )
}
