import React from 'react';
import { useHistory } from 'react-router-dom';
import _ from "lodash";
import { getSysInfo } from '../../modules/data-objects/sys-info';
import getCurrentDate from '../../modules/current-date';
import { startOfYear, endOfYear } from '../../modules/date-without-time';
import * as H from 'history';

/*
 * This component is split into 2 parts. The first part is the getYearSelection function which returns
 * important values you need pass as props to the actual component. The reason it's structured like this
 * is that the caller needs access to these important values as well
 */
class YearSelectorEntry {
    title!: string
    queryString!: string
    year!: number | null
}

class Props {
    yearSelectorValues!: YearSelectorEntry[];
    selectedEntry!: YearSelectorEntry;
}

const queryStringFromDateRange = (startDate: string, endDate: string) =>
    `?startdate=${startDate}&enddate=${endDate}`

const queryStringFromYearRange = (startYear: number, endYear: number) =>
    queryStringFromDateRange(startOfYear(startYear), endOfYear(endYear))

const queryStringFromYear = (year: number) => queryStringFromYearRange(year, year)

const getCurrentRange = (startDate: string | null, endDate: string | null): string =>
    (startDate || endDate)
        ? queryStringFromDateRange(startDate!, endDate!)
        : queryStringFromYear(getCurrentDate().getFullYear())

function getYearSelectorValues(selectedRange: string): YearSelectorEntry[] {
    const activityListConfig = getSysInfo().clientAppConfig.activityList
    const yearRange: YearSelectorEntry[] = _.range(activityListConfig.startYear, activityListConfig.endYear + 1)
        .map(year => ({
            title: year.toString(),
            queryString: queryStringFromYear(year),
            year
        }))
    yearRange.push({
        title: "Other",
        queryString: queryStringFromYearRange(activityListConfig.endYear + 1, activityListConfig.startYear - 1),
        year: null
    })
    if (!yearRange.some(o => o.queryString === selectedRange)) {
        yearRange.push({
            title: "Custom",
            queryString: selectedRange,
            year: null
        })
    }
    return yearRange
}

export function getYearSelection(location: H.Location<unknown>) {
    const searchParams = new URLSearchParams(location.search);
    const startDateString = searchParams.get("startdate");
    const endDateString = searchParams.get("enddate");
    const selectedRange = getCurrentRange(startDateString, endDateString);
    const yearSelectorValues = getYearSelectorValues(selectedRange);
    const selectedEntry = yearSelectorValues.find(o => o.queryString === selectedRange) ?? yearSelectorValues[yearSelectorValues.length - 1];
    return { yearSelectorValues, selectedEntry };
}

export default function ActivityListYearSelector({ yearSelectorValues, selectedEntry } : Props) {
    const history = useHistory()

    function yearOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;
        history.push(`/activities${value}`);
    }

    return (
        <>
            <div className="grid-x grid-padding-x">
                <div className="small-3 cell">
                    <label htmlFor="year" className="text-right middle calendar">Calendar year</label>
                </div>
                <div className="small-3 cell">
                    <select id="year"
                        value={selectedEntry.queryString}
                        onChange={yearOnChange}>
                        {yearSelectorValues.map(o =>
                            <option key={o.title} value={o.queryString} disabled={o.title === "Custom"}>{o.title}</option>
                        )}
                    </select>
                </div>
            </div>
        </>
    )
}

