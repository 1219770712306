export default function parseProblem(contentType: string | null, responseText:string) :string | null
{
    if (parseContentTypeHeader(contentType) !== "application/problem+json") {
        return null;
    }
    const problem = JSON.parse(responseText) as ProblemDetails;
    return problem?.title ?? null;
}

function parseContentTypeHeader(contentType: string | null) : string {
    const s = (contentType ?? "").trim();
    const i = s.indexOf(";");
    if (i === -1) { 
        return s;
    } else {
        return s.substr(0, i).trim();
    }
}

export class ProblemDetails {
    title?: string | null;
    detail?: string | null;
}
