import Subject from "../../modules/data-objects/subject"
import { LoggedInAs } from "../utils/logged-in-as"
import { useEffect, useState } from "react"
import { MentorRelationship, mentorRelationshipFromJson, mentorRelationshipIsEnded } from "../../modules/data-objects/mentor"
import cupidFetch from "../../modules/cupid-fetch"
import MentorRelationshipView from "./mentor-relationship-view"

class Props {
    subject!: Subject
}

export default function Mentoring({ subject }: Props) {
    const [mentorRelationships, setMentorRelationships] = useState<MentorRelationship[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchMentorRelationships = async () => {
            setMentorRelationships([])
            setLoading(true)
            const result = await cupidFetch<null, MentorRelationship[]>("/api/mentorrelationships")
            if (result) {
                setMentorRelationships(result.map(mentorRelationshipFromJson))
            }
            setLoading(false)
        }
        fetchMentorRelationships()
    }, [])

    return <div className="grid-container main-content">
        <div className="grid-x">
            <div className="cell medium-12">
                <h1 className="page-title">Mentoring</h1>
                <LoggedInAs subject={subject} />
            </div>
        </div>
        {mentorRelationships.find(mentorRelationshipIsEnded) && <div className="grid-x">
            <div className="cell medium-12 intro-text">
                <p>Mentor relationships which have ended are shown in grey text.</p>
            </div>
        </div>
        }
        <MentorRelationshipView
            subject={subject}
            mentorRelationships={mentorRelationships.filter(x => !x.isMentor)}
            loading={loading}
            isMentor={false}
        />
        <hr />
        <MentorRelationshipView
            subject={subject}
            mentorRelationships={mentorRelationships.filter(x => x.isMentor)}
            loading={loading}
            isMentor={true}
        />
    </div>
}
