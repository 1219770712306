import Subject from "../../modules/data-objects/subject"
import { LoggedInAs } from "../utils/logged-in-as"
import { useCallback, useEffect, useState } from "react"
import { MentorActivity, MentorRelationship, mentorActivityFromJson, mentorRelationshipFromJson, mentorRelationshipIsEnded } from "../../modules/data-objects/mentor"
import cupidFetch from "../../modules/cupid-fetch"
import { Accordion, AccordionItem, AccordionTitle } from "react-foundation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import MentorActivityRow from "./mentor--activity-row"
import { useHistory } from "react-router-dom"

class Props {
    subject!: Subject
    mentorRelationshipId!: string
}

export default function MentorActivities({ subject, mentorRelationshipId }: Props) {
    const history = useHistory()
    const [mentorActivities, setMentorActivities] = useState<MentorActivity[]>([])
    const [mentorRelationship, setMentorRelationship] = useState<MentorRelationship | null>(null)
    const [loading, setLoading] = useState(false)

    const fetchMentorActivities = useCallback(async () => {
        setMentorActivities([])
        const mentorActivities = await cupidFetch<null, MentorActivity[]>(`/api/mentorrelationships/${mentorRelationshipId}/activities`)
        if (mentorActivities) {
            setMentorActivities(mentorActivities.map(mentorActivityFromJson))
        }
    }, [mentorRelationshipId])

    const fetchMentorRelationship = useCallback(async () => {
        const mentorRelationship = await cupidFetch<null, MentorRelationship>(`/api/mentorrelationships/${mentorRelationshipId}`)
        if (mentorRelationship) {
            setMentorRelationship(mentorRelationshipFromJson(mentorRelationship))
        }
    }, [mentorRelationshipId])

    const initialLoad = useCallback(async () => {
        setLoading(true)
        await Promise.all([fetchMentorActivities(), fetchMentorRelationship()])
        setLoading(false)
    }, [fetchMentorActivities, fetchMentorRelationship])

    useEffect(() => { initialLoad() }, [initialLoad])

    const reloadActivityList = async () => {
        setLoading(true)
        await fetchMentorActivities()
        setLoading(false)
    }

    const addActivity = () => {
        history.push(`/mentorrelationships/${mentorRelationshipId}/activities/new`)
    }

    return <div className="grid-container main-content">
        <div>
            <div className="cell medium-12">
                <h1 className="page-title">
                    Mentoring activities
                    {!!mentorRelationship && <>
                        <span> - </span>
                        {mentorRelationship.isMentor ? "mentoring" : "being mentored by"}
                        <span> </span>
                        {mentorRelationship.displayName}
                    </>}
                </h1>
                <LoggedInAs subject={subject} />
            </div>
            <div className="grid-container">
                <Accordion>
                    {loading &&
                        <AccordionItem>
                            <AccordionTitle>Loading...</AccordionTitle>
                        </AccordionItem>}
                    {!loading && mentorActivities.map(mentorActivity =>
                        <MentorActivityRow
                            key={mentorActivity.mentorActivityId}
                            mentorRelationship={mentorRelationship!}
                            mentorActivity={mentorActivity}
                            reloadActivityList={reloadActivityList}
                        />
                    )}
                    {!(loading || mentorActivities.length) &&
                        <AccordionItem>
                            <AccordionTitle>No mentoring activities</AccordionTitle>
                        </AccordionItem>}
                </Accordion>
            </div>
            {mentorRelationship && !mentorRelationshipIsEnded(mentorRelationship) &&
                <div className="grid-container">
                    <div className="cell medium-4">
                        <button className="button" onClick={addActivity}>
                            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                            <span> Add activity</span>
                        </button>
                    </div>
                </div>
            }
        </div>
    </div>
}
