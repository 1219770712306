import Subject from '../../modules/data-objects/subject'
import ActivityList from './activity-list';
import { reviewGrantFromJson } from '../../modules/data-objects/review-grant'
import ProgressWidget from '../target-progress/progress-widget'
import { startOfYear, endOfYear } from '../../modules/date-without-time'
import { LoggedInAs } from '../utils/logged-in-as'

class Props {
    subject!: Subject
}

function getFullYear(startDate: string, endDate: string): number | null {
    const year = parseInt(startDate.split("-")[0])
    return startDate === startOfYear(year) && endDate === endOfYear(year)
        ? year
        : null
}

export default function ReviewerActivityList({ subject }: Props) {
    const authenticated = subject.authenticated!
    const reviewer = authenticated.reviewer!
    const reviewGrant = reviewGrantFromJson(reviewer.reviewGrant)
    const year = getFullYear(reviewGrant.activityStartDate, reviewGrant.activityEndDate)

    return (
        <>
            <div className="grid-container main-content">
                <div className="grid-x">
                    <div className="cell medium-12">
                        <h1 className="page-title">
                            <span>Review CPD for </span>
                            {reviewer.revieweeName}
                            <br />
                            <span>(Membership No. </span>
                            <strong>{reviewer.revieweeMembershipNo}</strong>
                            <span>)</span>
                        </h1>
                        <LoggedInAs subject={subject} />
                    </div>
                </div>
                {year &&
                    <div className="grid-x">
                        <div className="cell medium-7">
                            <ProgressWidget year={year} userId={reviewGrant.userId!} />
                        </div>
                    </div>
                }
            </div>
            <ActivityList
                queryString={`?userid=${reviewGrant.userId}&startdate=${reviewGrant.activityStartDate}&enddate=${reviewGrant.activityEndDate}`}
                reloadActivityListCallback={() => { }}
                subject={subject} />
        </>
    )
}
