export default class ActivityNote {
    activityNoteId: string | null = null
    activityId: string | null = null
    reviewerName: string | null = null
    reviewerEmail: string | null = null
    noteDate: Date | null = null
    noteText: string = ""
}

export function activityNoteFromJson(activityNote: ActivityNote) : ActivityNote {
    return {
        ...new ActivityNote(),
        ...activityNote,
        noteDate: new Date(activityNote.noteDate!.valueOf()),
    }
}
