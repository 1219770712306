import { getGlobal, deleteGlobal } from './globals';

export type ErrorLoggerType = (message?: any, ...optionalParams: any[]) => void;

const globalName = "logging";

class LoggingGlobals {
    errorLogger: ErrorLoggerType = console.error;
}

export function getLoggingGlobals(): LoggingGlobals {
    return getGlobal<LoggingGlobals>(LoggingGlobals, globalName);
}

export function resetLoggingGlobals(): void {
    deleteGlobal(globalName);
}

export default function logError(message?: any, ...optionalParams: any[]): void {
    getLoggingGlobals().errorLogger(message, ...optionalParams);
}
