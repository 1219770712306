import React, { useState, useEffect } from 'react'
import Activity from '../../modules/data-objects/activity'
import ActivityNote, { activityNoteFromJson } from '../../modules/data-objects/activity-note'
import cupidFetch from '../../modules/cupid-fetch'
import NoteDetails from './note-details'
import Subject from '../../modules/data-objects/subject'

class Props {
    activity!: Activity
    subject!: Subject
    dirtyNoteCallback!: (dirty: boolean) => void
}

export default function ActivityNoteList({ activity, subject, dirtyNoteCallback }: Props) {
    // if there are no notes and the user is a reviewer, we still want to render the activity note control,
    // which we do by passing in null for the note object
    const [activityNotes, setActivityNotes] = useState<(ActivityNote | null)[]>([])
    const url = `/api/activities/${activity.activityId}/notes`

    useEffect(
        () => {
            let isMounted = true;
            (async () => {
                const result = await cupidFetch<null, ActivityNote[]>(url)
                if (!isMounted) return
                if (!result) return
                setActivityNotes(
                    !result.length && subject.isReviewer()
                        ? [null]
                        : result.map(o => activityNoteFromJson(o))
                )
            })()
            return () => { isMounted = false }
        },
        [url, subject]
    )

    return (
        <>
            {activityNotes.map(o =>
                <NoteDetails
                    key = {o?.activityNoteId ?? "new"}
                    activityId={activity.activityId!}
                    activityNote={o}
                    subject={subject}
                    dirtyNoteCallback={dirtyNoteCallback} />
            )}
        </>
    )
}
