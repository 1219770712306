import React from 'react'
import SlideDown from 'react-slidedown'
import { getGlobal } from '../../modules/globals';
import 'react-slidedown/lib/slidedown.css'

const globalName = "slideDown";

class Props {
    children!: false | JSX.Element
}

export function getSlideDownGlobals(): SlideDownGlobals {
    return getGlobal<SlideDownGlobals>(SlideDownGlobals, globalName);
}

export class SlideDownGlobals {
    enableTransitions: boolean = true;
}

export default function CupidSlideDown({ children }: Props) {
    return (
        <>
            {getSlideDownGlobals().enableTransitions
                ? <SlideDown>{children}</SlideDown>
                : children
            }
        </>
    )
}
