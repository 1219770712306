import React, { useState, useEffect, FormEvent } from 'react'
import cupidFetch from '../modules/cupid-fetch'
import getCurrentDate from '../modules/current-date'
import { nanToNull } from '../modules/utils'
import Target from '../modules/data-objects/target'
import { useHistory } from 'react-router-dom'
import Subject from '../modules/data-objects/subject'
import { LoggedInAs } from './utils/logged-in-as'

class Props {
    subject!: Subject
}

export default function Targets({ subject }: Props) {
    const history = useHistory()
    const [points, setPoints] = useState<number | null>(null)
    const [hours, setHours] = useState<number | null>(null)
    const [loaded, setLoaded] = useState(false)
    const year = getCurrentDate().getFullYear()

    useEffect(
        () => {
            let isMounted = true;
                (async () => {
                    const result = await cupidFetch<null, Target>(`/api/targets/${year}`)
                    if (result && isMounted) {
                        setPoints(result.points)
                        setHours(result.hours)
                        setLoaded(true)
                    }
                })()
            return () => { isMounted = false }
        },
        [year]
    )

    const pointsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.valid) {
            setPoints(nanToNull(parseInt(event.target.value)))
        }
    }

    const hoursOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.valid) {
            setHours(nanToNull(parseFloat(event.target.value)))
        }
    }

    const onCloseClick = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const target: Target = { points, hours }
        const result = await cupidFetch<Target, Target>(`/api/targets/${year}`, "PUT", target)
        if (result) {
            history.push("/")
        }
    }

    return (
        <div className="grid-container main-content">
            <div className="grid-x">
                <div className="cell medium-12">
                    <h1 className="page-title">Set targets</h1>
                    <LoggedInAs subject={subject} />
                </div>
            </div>
            <div className="grid-x">
                <div className="cell medium-7 intro-text">
                    <p>If you have annual targets for either or both points and hours enter them here. These are used to show your actual against target position to date as you move throughout the year.</p>
                </div>
                <div className="cell medium-5"></div>
            </div>
            <form onSubmit={onCloseClick}>
                <fieldset disabled={!loaded}>
                    <div className="grid-x">
                        <div className="cell medium-6">
                            <div className="callout secondary form-box">
                                <div className="grid-x grid-padding-x">
                                    <div className=" cell">
                                        <label htmlFor="points">Points</label>
                                    </div>
                                    <div className="small-12 cell">
                                        <input type="number" step="1" min="0" max={1000000} id="points" value={points ?? ""} onChange={pointsOnChange} />
                                    </div>
                                    <div className=" cell">
                                        <label htmlFor="hours">Hours</label>
                                    </div>
                                    <div className="small-12 cell">
                                        <input type="number" step="0.5" min="0" max={1000000} id="hours" value={hours ?? ""} onChange={hoursOnChange} />
                                    </div>
                                    <div className="small-12 cell">
                                        <input type="submit" className="button float-right" value="Save" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}
