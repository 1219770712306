import React, { useEffect, useState } from 'react'
import cupidFetch from "../../modules/cupid-fetch"
import Subject, { SubjectDto } from '../../modules/data-objects/subject'
import { useLocation } from 'react-router-dom'
import { LoginParams } from './login-page'

class Props {
    loginCallback!: () => void
}

/*
 * Note:
 * If the authentication fails, we'd like to call loginCallback to notify the app component
 * that the user is logged out (because they might have been logged in before coming here).
 * Unfortunately that would cause the app component to re-mount this component and hence 
 * cause an infinite loop so we can't do it.
 * 
 * Fortunately thing the user can do from here is to navigate manually which this which will
 * reload the app component anyway, so it doesn't matter that we're putting it into an
 * inconsistent state.
*/

export default function ReviewerLogin({ loginCallback }: Props) {
    const locaton = useLocation()
    const token = new URLSearchParams(locaton.search).get("token") ?? ""
    const [failed, setFailed] = useState(false)

    useEffect(
        () => {
            const authenticate = async () => {
                const response = await cupidFetch<LoginParams, SubjectDto>('/api/session', 'POST', { token })
                if (!response) return
                const subject = new Subject(response)
                if (!subject.isAuthenticated()) {
                    setFailed(true)
                    // see note above
                } else {
                    loginCallback()
                }
            }
            authenticate()
        },
        [loginCallback, token]
    )

    return (
        <div>
            {failed ? "Invalid authentication token" : "Logging in, please wait..."}
        </div>
    );
}
