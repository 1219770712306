import { getGlobal, deleteGlobal } from './globals';

export type ShowAlertType = (message: string) => Promise<void>;
export type ShowConfirmType = (message: string) => Promise<boolean>;

const globalName = "currentDate";

class CurrentDateGlobals {
    getCurrentDate: () => Date = () => new Date();
}

export function getCurrentDateGlobals(): CurrentDateGlobals {
    return getGlobal<CurrentDateGlobals>(CurrentDateGlobals, globalName);
}

export function resetCurrentDateGlobals(): void {
    deleteGlobal(globalName);
}

export default function getCurrentDate(): Date {
    return getCurrentDateGlobals().getCurrentDate();
}
