import { useState } from "react"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { AccordionContent, AccordionItem, AccordionTitle } from "react-foundation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faList } from "@fortawesome/free-solid-svg-icons"
import CupidSlideDown from "../utils/cupid-slidedown"
import { formatDateWithoutTimeForDisplay } from "../../modules/date-without-time"
import { getSysInfo } from "../../modules/data-objects/sys-info"
import { MentorRelationship, mentorRelationshipIsEnded, otherPersonRole } from "../../modules/data-objects/mentor"
import { MentorAgreementDisplay } from "./MentorAgreementDisplay"
import Subject from "../../modules/data-objects/subject"

class Props {
    mentorRelationship!: MentorRelationship
    subject!: Subject;
}

export default function MentorRelationshipRow({ mentorRelationship, subject }: Props) {
    const [expanded, setExpanded] = useState(false)
    const history = useHistory()

    const mentorRelationshipType = getSysInfo()
        .mentorRelationshipTypes
        .find(x => x.mentorRelationshipTypeId === mentorRelationship.relationshipTypeId)
        ?.title

    const editClick = (mentorRelationshipId: string) => {
        history.push(`/mentorrelationships/${mentorRelationshipId}/edit`)
    }

    const activitiesClick = (mentorRelationshipId: string) => {
        history.push(`/mentorrelationships/${mentorRelationshipId}/activities`)
    }

    const isEnded = mentorRelationshipIsEnded(mentorRelationship)
    const otherPerson = otherPersonRole(mentorRelationship)

    return <AccordionItem>
        <AccordionTitle onClick={() => setExpanded(x => !x)}>
            <span className={isEnded ? "mentor-relationship-ended" : ""}>
                {mentorRelationship.displayName} - {mentorRelationship.email}
                &nbsp;
                (Membership No. {mentorRelationship.membershipNo})
                &nbsp;|&nbsp;
                {mentorRelationshipType}
            </span>
        </AccordionTitle>
        <CupidSlideDown>
            {expanded &&
                <AccordionContent isActive={true}>
                    {isEnded && <div className="callout warning">
                        Mentor relationship has ended - you cannot add/edit/delete activities.
                    </div>}
                    {!isEnded && mentorRelationship.otherPartyEnded && <div className="callout primary">
                        The {otherPerson} has indicated that the mentor relationship has ended.
                        However, until you have marked the relationship as ended you can continue
                        to add or edit activities.
                    </div>}
                    <div className="grid-x">
                        <div className="cell medium-6">
                            <h2>{mentorRelationship.email}</h2>
                            <div className="small button-group">
                                <fieldset>
                                    <button onClick={() => editClick(mentorRelationship.mentorRelationshipId!)} className="warning button">
                                        <FontAwesomeIcon icon={faPen} inverse />
                                        <span> Edit</span>
                                    </button>
                                    <button onClick={() => activitiesClick(mentorRelationship.mentorRelationshipId!)} className="button">
                                        <FontAwesomeIcon icon={faList} />
                                        <span> Activities...</span>
                                    </button>
                                </fieldset>
                            </div>
                            <MentorAgreementDisplay subject={subject} mentorRelationshipId={mentorRelationship.mentorRelationshipId!}></MentorAgreementDisplay>
                        </div>
                        <div className="cell medium-6">
                            <div className="callout secondary">
                                <p><strong>{_.startCase(otherPerson)} name:</strong> {mentorRelationship.displayName}</p>
                                <p><strong>Email:</strong> {mentorRelationship.email}</p>
                                <p><strong>Membership No:</strong> {mentorRelationship.membershipNo}</p>
                                <p><strong>Relationship type:</strong> {mentorRelationshipType}</p>
                                <p><strong>Start date:</strong> {formatDateWithoutTimeForDisplay(mentorRelationship.startDate)}</p>
                                {mentorRelationship.endDate &&
                                    <p><strong>End date:</strong> {formatDateWithoutTimeForDisplay(mentorRelationship.endDate)}</p>
                                }
                            </div>
                        </div>
                        {mentorRelationship.notes &&
                            <div className="cell medium-6 medium-offset-6">
                                <div className="callout warning">
                                    <h5><strong>Notes</strong></h5>
                                    <p className="textblock">{mentorRelationship.notes}</p>
                                </div>
                            </div>
                        }
                    </div>
                </AccordionContent>
            }
        </CupidSlideDown>
    </AccordionItem>

}
