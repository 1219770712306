import React from 'react';
import cupidFetch from "../../modules/cupid-fetch";
import Subject, { SubjectDto } from '../../modules/data-objects/subject';
import { unicode_no_break_space } from '../../modules/utils';
import { iheemWebSiteUrl } from '../app/app';

class Props {
    loginCallback!: () => void
    appDescription!: string
}

class State {
    waitingForServer: boolean = false;
    showInvalidUserNameError: boolean = false;
    userName: string = "";
    password: string = "";
}

export interface LoginParams {
    userName?: string,
    password?: string,
    token?: string,
}

class LoginPage extends React.Component<Props, State> {
    state: State = new State();

    userNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(
            { userName: event.target.value }
        );
    }

    passwordOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(
            { password: event.target.value }
        );
    }

    loginButtonClick = async () => {
        this.setState({ waitingForServer: true, showInvalidUserNameError: false });
        const response = await cupidFetch<LoginParams, SubjectDto>(
            '/api/session',
            'POST',
            {
                userName: this.state.userName,
                password: this.state.password,
            }
        );
        this.setState({ waitingForServer: false });
        if (!response) return
        const subject = new Subject(response)
        if (!subject.isAuthenticated()) {
            this.setState({ showInvalidUserNameError: true })
        } else {
            this.props.loginCallback()
        }
    }

    render() {
        const loginButtonDisabled: boolean = (!this.state.userName) || (!this.state.password) || (this.state.waitingForServer);
        const resetPasswordLink = `${iheemWebSiteUrl}/wp-login.php?action=lostpassword`
        const termsLink = `${iheemWebSiteUrl}/terms-and-conditions/`
        const privacyLink = `${iheemWebSiteUrl}/privacy-policy/`
        return (
            <>
                <div className="grid-container">
                    <div className="grid-x">
                        <div className="login-box cell medium-5">
                            <form className="callout text-center" onSubmit={e => e.preventDefault()}>
                                <h2>{this.props.appDescription} Login</h2>
                                <span className="is-invalid-label">
                                    {this.state.showInvalidUserNameError ? "Invalid email/password" : unicode_no_break_space}
                                </span>
                                <div className="floated-label-wrapper">
                                    <label htmlFor="userName">Email</label>
                                    <input type="text" id="userName" value={this.state.userName} onChange={this.userNameOnChange} placeholder="Email" />
                                </div>
                                <div className="floated-label-wrapper">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" id="password" value={this.state.password} onChange={this.passwordOnChange} placeholder="Password" />
                                </div>
                                <div>
                                    <input className="button expanded" type="submit" value="Login" data-testid="loginButton" onClick={this.loginButtonClick} disabled={loginButtonDisabled} />
                                </div>
                            </form>
                        </div>
                        <div className=" cell ">
                            <h3 className="text-center">What’s my password?</h3>
                            <p className="text-center">
                                <span>To log into {this.props.appDescription}, use the same username and password as for the IHEEM Web site. If you have forgotten your password or don't have one, go to </span>
                                <a href={resetPasswordLink}>{resetPasswordLink}</a>
                                <span> to set one, then return here to log in.</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="grid-x">
                        <div className=" cell ">
                            <p>&nbsp;</p>
                            <p className="text-center" style={{ fontSize: "0.8rem" }}>
                                <span>By logging into this site you agree to the terms and conditions at </span>
                                <a href={termsLink} target="_blank" rel="noopener noreferrer">{termsLink}</a>
                                <span> and the privacy policy at </span>
                                <a href={privacyLink} target="_blank" rel="noopener noreferrer">{privacyLink}</a>
                                <span>.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LoginPage;
