import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ReviewGrant, { reviewGrantFromJson, newReviewGrant } from '../../modules/data-objects/review-grant'
import cupidFetch from '../../modules/cupid-fetch'
import ReviewGrantRow from './review-grant-row'
import { showConfirm, showAlert } from '../../modules/show-alert'
import { LoggedInAs } from '../utils/logged-in-as'
import Subject from '../../modules/data-objects/subject'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Accordion, AccordionItem, AccordionTitle } from 'react-foundation'

/* We need to talk about newSaveId...
 *
 * When the user adds a new one, it goes into the list with an ID of null. when it's saved for the first time
 * the id changes. but of course, the id is the key for the <ReviewGrantRow> so we need to be a bit clever,
 * and this means remembering the ID of the most recent newly saved one. It's used in 2 places:
 * 
 * 1. When retrieving a list from the server into state, if the current state contains an unsaved one we need to add that
 * to the top of the list unless we've just done a new save (in which case the newly saved one will be already included)
 * 
 * 2. When rendering the <ReviewGrantRow> for the newly saved one for the first time, we want it to be expanded so
 * that the user can click the email button if they like.
 * 
 * note that even with this jiggery-pokery, a newly saved row is removed from state and added back in with a different
 * id, so it slides up and back down in a different place which isn't ideal. maybe a better solution would be to
 * use our own ID for the key which stays constant and map this into the server id separately.
 */

class State {
    reviewGrants: ReviewGrant[] | null = null
    waitingForServer: boolean = false
    newSaveId: string | null = null;
}

class Props {
    subject!: Subject;
}

class _ReviewGrantPage extends React.Component<Props & RouteComponentProps, State> {
    state: State = new State()
    mounted: boolean = false

    async componentDidMount() {
        this.mounted = true
        this.loadReviewGrants(null)
    }

    async loadReviewGrants(newSaveId: string | null) {
        const reviewGrants = await cupidFetch<null, ReviewGrant[]>("/api/reviewgrants")
        if (!this.mounted) return
        if (!reviewGrants) return
        this.setState(state => ({
            newSaveId,
            reviewGrants: [
                ...(state.reviewGrants ?? []).filter(o => !o.reviewGrantId && !newSaveId),
                ...reviewGrants.map(o => reviewGrantFromJson(o))
            ]
        }))
    }

    componentWillUnmount() { this.mounted = false }

    saveEdit = async (updates: Partial<ReviewGrant>) => {
        this.setState({ waitingForServer: true })
        const reviewGrantId = updates.reviewGrantId;
        this.setState(state => ({
            reviewGrants:
                state.reviewGrants!.map(o =>
                    o.reviewGrantId === reviewGrantId
                        ? { ...o, ...updates }
                        : o)
        }), async () => {
            const isNew = !updates.reviewGrantId
            const method = isNew ? "POST" : "PUT"
            const urlSuffix = isNew ? "" : `/${reviewGrantId}`
            const toSave = this.state.reviewGrants?.find(o => o.reviewGrantId === reviewGrantId)
            const saveResult = await cupidFetch<ReviewGrant, ReviewGrant>(`/api/reviewgrants${urlSuffix}`, method, toSave)
            if (!saveResult) return
            const newSaveId = isNew ? saveResult.reviewGrantId : null
            await this.loadReviewGrants(newSaveId)
            if (!this.mounted) return
            this.setState({ waitingForServer: false })
            if (isNew) {
                await showAlert("Reviewer access has been granted. Click \"send email\" to send them an email with a link to review your CPD record.")
            }
        })
    }

    deleteGrant = async (reviewGrantId: string | null) => {
        if (reviewGrantId) {
            if (!await showConfirm("Delete this grant?")) return
            this.setState(state => ({
                reviewGrants:
                    state.reviewGrants!.filter(o => o.reviewGrantId !== reviewGrantId)
            }))
            await cupidFetch<ReviewGrant, null>(`/api/reviewgrants/${reviewGrantId}`, "DELETE")
            this.loadReviewGrants(null)
        } else {
            this.setState(state => ({ reviewGrants: state.reviewGrants!.filter(o => o.reviewGrantId) }))
        }
    }

    createGrant = () => {
        this.setState(state => ({ reviewGrants: [newReviewGrant(null), ...state.reviewGrants!] }))
    }

    renderTableBody(): JSX.Element[] | JSX.Element {
        if (!this.state.reviewGrants) return (
            <AccordionItem>
                <AccordionTitle>Loading...</AccordionTitle>
            </AccordionItem>
        ); else if (this.state.reviewGrants.length === 0) return (
            <AccordionItem>
                <AccordionTitle>No review access granted</AccordionTitle>
            </AccordionItem>
        ); else return (
            this.state.reviewGrants.map(reviewGrant =>
                <ReviewGrantRow
                    key={reviewGrant.reviewGrantId ?? ""}
                    reviewGrant={reviewGrant}
                    saveEdit={this.saveEdit}
                    deleteGrant={this.deleteGrant}
                    waitingForServer={this.state.waitingForServer}
                    initiallyExpanded={this.state.newSaveId === reviewGrant.reviewGrantId}
                />
            )
        )
    }

    render() {
        // you can only add a new one if the list has loaded and there aren't any new ones already
        const enableAdd = !this.state.waitingForServer && this.state.reviewGrants && !(this.state.reviewGrants.find(o => !o.reviewGrantId))
        return (
            <>
                <div className="grid-container main-content">
                    <div className="grid-x">
                        <div className="cell medium-12">
                            <h1 className="page-title">Grant reviewer access</h1>
                            <LoggedInAs subject={this.props.subject} />
                        </div>
                    </div>
                    <div className="grid-x">
                        <div className="cell medium-7 intro-text">
                            <p>
                                You can grant a number of reviewers access to your CPD to view and make notes.
                                Reviewers cannot see each others' notes.
                                For each reviewer, enter the the date their access expires, and the date range of activity they can view.
                                When you are ready for them to have access, click "Send email" which will send them an invitation email with a link to review the CPD.
                            </p>
                        </div>
                        <div className="cell medium-5"></div>
                    </div>
                </div>
                <div className="grid-container">
                    <Accordion>
                        {this.renderTableBody()}
                    </Accordion>
                </div>
                <div className="grid-container">
                    <div className="cell medium-4">
                        <button className="button" disabled={!enableAdd} onClick={this.createGrant}>
                            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                            <span> Add reviewer</span>
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

const ReviewGrantPage = withRouter(_ReviewGrantPage)
export default ReviewGrantPage
