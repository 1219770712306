import React from 'react'
import Nav from './nav'
import Subject from '../../modules/data-objects/subject'
import { NavEntry } from './app-config'

class Props {
    loginCallback!: () => void
    subject!: Subject
    renderLayoutRoutes!: (subject: Subject) => JSX.Element
    getNavEntries!: (subject: Subject) => NavEntry[]
}

function Layout({ subject, loginCallback, renderLayoutRoutes, getNavEntries }: Props) {
    return (
        <div>
            <Nav loginCallback={loginCallback} subject={subject} getNavEntries={getNavEntries} />
            {
                renderLayoutRoutes(subject)
            }
        </div>
    )
}

export default Layout
