import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import cupidFetch from '../modules/cupid-fetch';
import VerifyEventSessionResult from '../modules/data-objects/verify-event-session-result';
import Activity, { newActivity, activityFromJson, getEventSessionPropertiesToCopy } from '../modules/data-objects/activity';
import { showAlert } from '../modules/show-alert';

class Props {
    eventSessionCode?: string | null;
}

export default function AddOrEditCpd({ eventSessionCode }: Props) {
    const history = useHistory()

    useEffect(
        () => {
            (async () => {
                if (!eventSessionCode) {
                    await showAlert("Error: No event session code specified");
                    return;
                }
                const url = `/api/activities/verifyeventsession?eventsessioncode=${encodeURIComponent(eventSessionCode)}`
                const verifyEventSessionResult = await cupidFetch<null, VerifyEventSessionResult>(url, "GET");
                if (!verifyEventSessionResult) return;
                if (verifyEventSessionResult.existingActivity) {
                    history.push(`/activities/${verifyEventSessionResult.existingActivity.activityId}/edit`);
                } else if (!verifyEventSessionResult.eventSession) {
                    await showAlert(`Error: Event Session code ${eventSessionCode} not found`);
                } else {
                    const activity = {
                        ...newActivity(null),
                        ...getEventSessionPropertiesToCopy(activityFromJson(verifyEventSessionResult.eventSession))
                    };
                    const savedActivity = await cupidFetch<Activity, Activity>(`/api/activities`, "POST", activity);
                    if (!savedActivity) return null;
                    history.push(`/activities/${savedActivity.activityId}/edit`);
                }
            })()
        },
        [eventSessionCode, history]
    )

    return (
        <div className="grid-container main-content">
            <div className="grid-x">
                <div className="cell medium-12">
                    <p>Loading..</p>
                    <p>
                        <Link to="/">Home</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

