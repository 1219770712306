import { AppConfig } from "./app-config";
import Subject from "../../modules/data-objects/subject";
import { Route } from "react-router-dom";
import React from "react";
import Browse from "../committees/browse";
import * as H from 'history';

export const appConfigCommittees: AppConfig = {
    appDescription: "MyIHEEM Committees",
    renderNonLayoutRoutes: (subject: Subject, history: H.History<unknown>) => [],
    renderLayoutRoutes: (subject: Subject) =>
        <Route>
            <Browse subject={subject} />
        </Route>
    ,
    getNavEntries: (subject: Subject) => [],
}
