import React from 'react'
import ReviewGrant from '../../modules/data-objects/review-grant'
import { formatDateWithoutTimeForDisplay } from '../../modules/date-without-time'
import { showConfirm, showAlert } from '../../modules/show-alert'
import cupidFetch from '../../modules/cupid-fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Props {
    reviewGrant!: ReviewGrant
    startEditing!: () => void
    deleteGrant!: (reviewGrantId: string) => void
    waitingForServer!: boolean
}

export default function ViewGrant({ reviewGrant, startEditing, deleteGrant, waitingForServer }: Props) {

    const sendNotification = async () => {
        const msg = `Send an email notification to ${reviewGrant.reviewerEmail}?`
        if (!await showConfirm(msg)) return
        const response = await cupidFetch(`/api/reviewgrants/${reviewGrant.reviewGrantId}/sendnotification`, "POST")
        if (!response) return
        await showAlert("Email notification sent successfully.\nIf the reviewer has not received the email within half an hour, make sure they check their \"junk\" or \"spam\" folder.")
    }

    return (
        <>
            <div className="cell medium-6">
                <h2>{reviewGrant.reviewerName}</h2>
                <div className="small button-group">
                    <fieldset disabled={!reviewGrant.reviewGrantId || waitingForServer}>
                        <button onClick={startEditing} className="warning button">
                            <FontAwesomeIcon icon={faPen} inverse />
                            <span> Edit</span>
                        </button>
                        <button onClick={() => deleteGrant(reviewGrant.reviewGrantId!)} className="alert button">
                            <FontAwesomeIcon icon={faTrash} />
                            <span> Delete</span>
                        </button>
                        <button onClick={sendNotification} className="button">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span> Send email</span>
                        </button>
                    </fieldset>
                </div>
            </div>
            <div className="cell medium-6">
                <div className="callout secondary">
                    <p><strong>Email:</strong> {reviewGrant.reviewerEmail}</p>
                    <p><strong>Expires:</strong> {formatDateWithoutTimeForDisplay(reviewGrant.expiryDate)}</p>
                    <p><strong>Activity from:</strong> {formatDateWithoutTimeForDisplay(reviewGrant.activityStartDate)}</p>
                    <p><strong>Activity to:</strong> {formatDateWithoutTimeForDisplay(reviewGrant.activityEndDate)}</p>
                </div>
            </div>
        </>
    )
}
