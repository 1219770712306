import React from 'react';
import cupidFetch from "../../modules/cupid-fetch";
import Activity, { activityFromJson, newActivity } from '../../modules/data-objects/activity';
import { showAlert } from '../../modules/show-alert';
import VerifyEventSessionResult from '../../modules/data-objects/verify-event-session-result';

class Props {
    eventSessionMode!: boolean;
    eventSessionSelected!: (eventSession: Activity | null) => void;
    initialEventSessionCode!: string;
    dirtyCallback!: (dirty: boolean) => void;
    activityId!: string | null;
}

// there are basically 5 cases for the state:
// 1. old and new values both blank => button disabled
// 2. old value blank => verify
// 3. new value blank => clear
// 4. old and new are the same => clear
// 5. old and new different => verify

class State {
    eventSessionCode: string = "";
    constructor(initialEventSessionCode: string) {
        this.eventSessionCode = initialEventSessionCode;
    }
}

class EventSessionCode extends React.Component<Props, State> {
    state: State = new State(this.props.initialEventSessionCode);

    async componentDidUpdate(prevProps: Props) {
        if (prevProps.initialEventSessionCode !== this.props.initialEventSessionCode) {
            this.setState({ eventSessionCode: this.props.initialEventSessionCode });
        }
    }

    eventSessionCodeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        this.setState({ eventSessionCode: value });
        this.props.dirtyCallback(value !== this.props.initialEventSessionCode);
    }

    async verifyEventSession(eventSessionCode: string) {
        const url = `/api/activities/verifyeventsession?eventsessioncode=${encodeURIComponent(eventSessionCode)}`
        return await cupidFetch<null, VerifyEventSessionResult>(url, "GET");
    }

    async eventSessionModeVerify(clear: boolean) {
        if (this.props.initialEventSessionCode) {
            // case 3, 4, 5
            const previousEventSession = await this.verifyEventSession(this.props.initialEventSessionCode);
            if (!previousEventSession) return;
            if (previousEventSession.existingActivity) {
                await showAlert(`Previous Session code "${this.props.initialEventSessionCode}" still in use`);
                this.setState({ eventSessionCode: this.props.initialEventSessionCode });
                this.props.dirtyCallback(false);
                return;
            }
        }
        if (!clear) {
            // case 2, 5
            const newEventSession = await this.verifyEventSession(this.state.eventSessionCode);
            if (!newEventSession) return;
            if (newEventSession.eventSession && newEventSession.eventSession.activityId !== this.props.activityId) {
                await showAlert(`Session code "${this.state.eventSessionCode}" already in use`);
                return;
            }
        }
        if (clear) {
            this.props.eventSessionSelected(null);
            this.setState({ eventSessionCode: "" })
        } else {
            this.props.eventSessionSelected({ ...newActivity(null), eventSessionCode: this.state.eventSessionCode });
        }
    }

    async normalModeVerify(clear: boolean) {
        if (clear) {
            // case 3, 4
            this.props.eventSessionSelected(null);
            this.setState({ eventSessionCode: "" })
        } else {
            // case 2, 5
            const response = await this.verifyEventSession(this.state.eventSessionCode);
            if (!response) return;
            if (!response.eventSession) {
                await showAlert(`Session code "${this.state.eventSessionCode}" could not be found`);
                return;
            }
            // if you delete the session code then add it back again immediately before an auto-save, you
            // will get back existingActivity as current activity but that's not an error
            if (response.existingActivity && response.existingActivity.activityId !== this.props.activityId) {
                await showAlert(`Session code "${this.state.eventSessionCode}" already referenced by another activity`);
                return;
            }
            const eventSession = activityFromJson(response.eventSession);
            this.props.eventSessionSelected(eventSession);
            this.setState({ eventSessionCode: eventSession.eventSessionCode });
        }
    }

    onVerifyClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.target as HTMLButtonElement;
        const clear: boolean = target.textContent === "Clear";
        if (this.props.eventSessionMode) {
            this.eventSessionModeVerify(clear);
        } else {
            this.normalModeVerify(clear);
        }
    };

    render() {
        const { buttonLabel, buttonDisabled } = getButtonState(this.state.eventSessionCode, this.props.initialEventSessionCode);
        return (
            <div className="cell">
                <div className="input-group">
                    {/* the label is styled wrongly but needed for testing - really we should just fix the styling */}
                    <label htmlFor="eventSessionCode" style={{ display: "none" }}>Session code</label>
                    <span className="input-group-label">Session code</span>
                    <input type="text" id="eventSessionCode" className="input-group-field" value={this.state.eventSessionCode} maxLength={256} onChange={this.eventSessionCodeOnChange} />
                    <div className="input-group-button">
                        <button disabled={buttonDisabled} className="button" onClick={this.onVerifyClick}>{buttonLabel}</button>
                    </div>
                </div>
            </div>
        )
    }
}

// this function exported for unit testing
export function getButtonState(eventSessionCode: string, verifiedCode: string) {
    const buttonLabel = (!verifiedCode || (eventSessionCode && eventSessionCode !== verifiedCode))
        ? "Verify"
        : "Clear";
    const buttonDisabled = !verifiedCode && !eventSessionCode;
    return { buttonLabel, buttonDisabled };
}

export default EventSessionCode;
