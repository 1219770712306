import React, { useState } from 'react'
import Activity from '../../modules/data-objects/activity'
import ActivityDetails from './activity-details'
import { formatDateWithoutTimeForDisplay } from '../../modules/date-without-time'
import { showAlert } from '../../modules/show-alert'
import Subject from '../../modules/data-objects/subject'
import { AccordionItem, AccordionTitle, AccordionContent } from 'react-foundation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-regular-svg-icons'
import CupidSlideDown from '../utils/cupid-slidedown'

class Props {
    activity!: Activity
    reloadActivityList!: () => void
    subject!: Subject
}

export default function ActivityListRow({ activity, reloadActivityList, subject }: Props) {
    const [expanded, setExpanded] = useState(false)
    const [allowContract, setallowContract] = useState(true)

    const expandButtonClick = () => {
        if (expanded && !allowContract) {
            showAlert("You are currently editing a note. Please either save or delete the note to continue.")
            return;
        }
        setExpanded(o => !o)
    }

    return (
        <AccordionItem isActive={expanded}>
            <AccordionTitle onClick={expandButtonClick}>
                <span title={activity.hasNotes ? "Notes are available for this CPD" : undefined}>
                    <span className={activity.isCompleted ? "" : "uncompleted"}>{formatDateWithoutTimeForDisplay(activity.activityDate)}</span>
                    <span> | </span>
                    <span className={activity.isCompleted ? "" : "uncompleted"}>{activity.title}</span>
                    {activity.hasNotes &&
                        <>
                            <span> </span>
                            <FontAwesomeIcon icon={faFileAlt} />
                        </>
                    }
                </span>
            </AccordionTitle>
            <CupidSlideDown>
                {expanded &&
                    <AccordionContent isActive={true}>
                        <ActivityDetails
                            activity={activity}
                            subject={subject}
                            dirtyNoteCallback={dirty => setallowContract(!dirty)}
                            reloadActivityList={reloadActivityList} />
                    </AccordionContent>
                }
            </CupidSlideDown>
        </AccordionItem>
    )
}
