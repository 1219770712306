import { faFile } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import cupidFetch from '../modules/cupid-fetch'
import PdpDocument from '../modules/data-objects/pdp-document'
import Subject from '../modules/data-objects/subject'
import FileUpload, { UploadState } from './utils/file-upload'
import { LoggedInAs } from './utils/logged-in-as'

class Props {
    subject!: Subject
}

export default function Pdp({ subject }: Props) {
    const [documents, setDocuments] = useState<PdpDocument[] | null>(null)
    const [nonce, setNonce] = useState(0)

    useEffect(
        () => {
            let isMounted = true;
            (async () => {
                setDocuments(null)
                const result = await cupidFetch<null, PdpDocument[]>(`/api/pdpdocuments`)
                if (result && isMounted) {
                    setDocuments(result)
                }
            })()
            return () => { isMounted = false }
        },
        [nonce]
    )

    const uploadInProgressCallback = (uploadState : UploadState) => {
        setNonce(x => x + 1)
    }

    const getFileUploadUrlCallback = async (): Promise<string | null> => {
        return `/api/pdpdocuments`
    }

    return (
        <div className="grid-container main-content">
            <div className="grid-x">
                <div className="cell medium-12">
                    <h1 className="page-title">Personal Development Plan</h1>
                    <LoggedInAs subject={subject} />
                </div>
            </div>
            <div className="grid-x">
                <div className="cell medium-7 intro-text">
                    <p>You should normally make one PDP document per year. Download the template from the link on the right and open in Microsoft Word.</p>
                    <p>Note: You should use MS Word 2013 or later; the template is not compatible with OpenOffice or LibreOffice.</p>
                </div>
                <div className="cell medium-4 medium-offset-1">
                    <a className="button" href="pdp-template.xlsx" target="_blank">Word Template</a>
                </div>
            </div>
            <div className="grid-x">
                <div className="cell small-4">
                    <FileUpload
                        uploadInProgressCallback={uploadInProgressCallback}
                        getPostUrlCallback={getFileUploadUrlCallback} />
                </div>
            </div>
            <div className="grid-x">
                <div className="cell medium-7">
                    <ul className="no-bullet" data-testid="files">
                        {(!documents) &&
                            <li>Loading...</li>
                        }
                        {documents && (!documents.length) &&
                            <li>(no documents)</li>
                        }
                        {documents?.map(x =>
                            <li key={x.pdpDocumentId}>
                                <a href={`/api/pdpdocuments/${encodeURIComponent(x.pdpDocumentId!)}`} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFile} />
                                    <span> </span>
                                    {x.fileName}
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}
