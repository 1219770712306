import React, { useEffect, useState } from "react";
import ListFolderResult from "../../modules/data-objects/list-folder-result";
import cupidFetch from "../../modules/cupid-fetch";
import { Link, useLocation } from "react-router-dom";
import { getBreadcrumbs } from "../../modules/utils";
import { LoggedInAs } from "../utils/logged-in-as";
import Subject from "../../modules/data-objects/subject";
import { Breadcrumbs, BreadcrumbItem } from 'react-foundation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faFile } from '@fortawesome/free-regular-svg-icons'
import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons'

class Props {
    subject!: Subject
}

/*
 * Note: if there's a path with a % in it, you can navigate it OK in the app but if you try to
 * access it directly from the browser (e.g. make a shortcut or type the URL) you'll get a react
 * error due to this:
 *  https://github.com/ReactTraining/react-router/issues/5812
 *  https://github.com/ReactTraining/history/issues/505
 * 
 * The bug has been closed but there is no easy workaround.
 * The flip side of this is that when we come to make the breadcrumb trail we need to urlDecode
 * the segments for display, but becauase percent isn't encoded properly the decode will fail.
 * hence we need this tryToUrlDecode function 
 * 
 * Also note that a path containing the sequence "&#" will also fail but that's purely down to
 * the MsGraph SharePoint API and nothing to do with this.
 */
function tryToUrlDecode(s: string) {
    try {
        return decodeURIComponent(s)
    } catch (e) {
        return s
    }
}

// we need to encode each part of the path but not the slashes
const urlEncodePath = (s: string) => s.split("/").map(encodeURIComponent).join("/")

export default function Browse({ subject }: Props) {
    const [listFolderResult, setListFolderResult] = useState<ListFolderResult | null>(null)
    const location = useLocation()
    const path = location.pathname
    const folderEmpty = listFolderResult && (listFolderResult.files.length + listFolderResult.folders.length === 0)

    useEffect(
        () => {
            let isMounted = true;
            (async () => {
                setListFolderResult(null)
                const result = await cupidFetch<null, ListFolderResult>(`/api/folder?path=${(path)}`)
                if (result && isMounted) {
                    setListFolderResult(result)
                }
            })()
            return () => { isMounted = false }
        },
        [path]
    )

    return (
        <div className="grid-container main-content">
            <div className="grid-x">
                <div className="cell medium-12">
                    <h1 className="page-title">Committee document downloads</h1>
                    <LoggedInAs subject={subject} />
                </div>
            </div>
            <div className="grid-x">
                <div className="cell medium-12 intro-text">
                    <p>Welcome to MyIHEEM Committees. This area is available for members to view and download documents and meeting papers for all committees and technical platforms they sit on.</p>
                    <p>If you download any documents please ensure you delete the copy from your computer once you have finished with it.</p>
                    <p>If you don't see any folders this may be because you are not set up as a committee member within our systems. If you believe you should be, please contact head office for assistance.</p>
                </div>
            </div>
            <Breadcrumbs data-testid="breadcrumbs">
                <BreadcrumbItem>
                    <Link to="/">Home</Link>
                </BreadcrumbItem>
                {getBreadcrumbs(path).map((o, i) =>
                    <BreadcrumbItem key={i}>
                        <Link to={urlEncodePath(o.path)}>{tryToUrlDecode(o.name)}</Link>
                    </BreadcrumbItem>
                )}
            </Breadcrumbs>
            <ul className="no-bullet" data-testid="folders">
                {path !== "/" &&
                    <li key="..">
                        <Link to={`..`} title="Parent folder">
                            <FontAwesomeIcon icon={faLevelUpAlt} />
                        </Link>
                    </li>
                }
                {listFolderResult?.folders.map(s =>
                    <li key={s}>
                        <Link to={`${urlEncodePath(path + s)}/`}>
                            <FontAwesomeIcon icon={faFolder} />
                            <span> </span>
                            {s}
                        </Link>
                    </li>
                )}
            </ul>
            <ul className="no-bullet" data-testid="files">
                {folderEmpty &&
                    <li>(Empty folder)</li>
                }
                {listFolderResult?.files.map(s =>
                    <li key={s}>
                        <a href={`/api/document?path=${path + urlEncodePath(s)}`} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFile} />
                            <span> </span>
                            {s}
                        </a>
                    </li>
                )}
            </ul>
        </div>
    )
}
