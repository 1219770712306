import { getGlobal } from './globals';
import logError from "./logging";
import { showAlert } from './show-alert';
import parseProblem from "./rfc7807-problem"

export type fetchType = (input: RequestInfo, init?: RequestInit) => Promise<Response>;
export type cupidFetchType = <TPayload, TResult>(url: string, method: string, payload: TPayload) => Promise<TResult | null>;

const globalName = "cupidFetch";

class CupidFetchGlobals {
    prefix: string = "";
    fetchModule: fetchType = window.fetch.bind(window);
    cupidFetch: cupidFetchType = cupidFetchImpl;
}

export function getCupidFetchGlobals(): CupidFetchGlobals {
    return getGlobal<CupidFetchGlobals>(CupidFetchGlobals, globalName);
}

export default async function cupidFetch<TPayload, TResult>(url: string, method: string = "GET", payload?: TPayload): Promise<TResult | null> {
    return await getCupidFetchGlobals().cupidFetch(url, method, payload);
}

function callShowAlert(s?: string | null) {
    const msg = s ?? "Error communicating with server - see console for details";
    showAlert(msg);
}

// returns body and/or content-type header
const buildRequest = (method: string, payload: any) => {
    if (method === "GET") {
        return {
            headers: { 'Content-Type': 'application/json' }
        }
    } else if (payload instanceof FormData) {
        return {
            body: payload
            // fetch will set the content-type
        }
    } else {
        return {
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }
    }
}

async function cupidFetchImpl<TPayload, TResult>(url: string, method: string, payload?: TPayload): Promise<TResult | null> {
    let response: Response | undefined = undefined;
    const globals = getCupidFetchGlobals();
    try {
        response = await globals.fetchModule(globals.prefix + url, {
            method,
            ...buildRequest(method, payload)
        });
    } catch (ex) {
        logError(`Error occurred sending ${method} ${url}:`)
        logError(ex);
        callShowAlert();
        return null;
    }

    const responseText = await response.text();

    if (response.status !== 200 && response.status !== 204) {
        logError(`Unexpected response status ${response.status} from ${method} ${url}:`)
        logError(response)
        logError(responseText)
        let message: string | null
        if (response.status === 401) {
            message = "You have been logged out due to inactivity. Please refresh and log in again"
        } else {
            message = parseProblem(response.headers.get("content-type"), responseText)
        }
        callShowAlert(message)
        return null
    }

    if (responseText === "") {
        return {} as TResult;
    }

    try {
        return JSON.parse(responseText);
    } catch (ex) {
        logError(`Error parsing JSON response from ${method} ${url}:`)
        logError(ex);
        logError(response)
        logError(responseText);
        callShowAlert();
        return null;
    }
}


