import React from 'react'
import cupidFetch from "../../modules/cupid-fetch"
import Activity, { activityFromJson } from '../../modules/data-objects/activity'
import ActivityListView from './activity-list-view'
import Subject from '../../modules/data-objects/subject'

class State {
    activities: Activity[] | null = null
}

class Props {
    reloadActivityList!: () => void
    subject!: Subject
}

class DraftActivityList extends React.Component<Props, State> {
    state: State = new State()
    mounted: boolean = false

    async componentDidMount() {
        this.mounted = true
        this.loadActivityList()
    }

    loadActivityList = async () => {
        this.mounted = true
        const url = "/api/activities?alluncompleted=true"
        const activities = await cupidFetch<null, Activity[]>(url)
        if (!this.mounted) return
        if (activities) {
            this.setState({ activities: activities.map(o => activityFromJson(o)) })
        }
    }

    reloadActivityList = async () => {
        await this.loadActivityList()
        this.props.reloadActivityList()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        return (
            this.state.activities && (!!this.state.activities.length) &&
            <ActivityListView reloadActivityList={this.reloadActivityList} activities={this.state.activities} subject={this.props.subject} />
        )
    }
}

export default DraftActivityList
