import { getCurrentDateWithoutTime } from "../date-without-time"

export class MentorRelationshipType {
    mentorRelationshipTypeId: string = "";
    title: string = "";
}

export class MentorAgreement {
    mentorAgreementId?: string | null;
    mentorRelationshipId?: string | null;
    fileName?: string | null;
    contentType?: string | null;
    fileSize?: number | null;
}

export class MentorRelationship {
    mentorRelationshipId: string | null = null
    isMentor: boolean = false
    relationshipTypeId: string | null = null
    userId: string | null = null
    email: string | null = null
    displayName: string | null = null
    membershipNo: string | null = null
    startDate!: string
    notes: string = ""
    endDate: string | null = null
    otherPartyEnded: boolean = false
}

export function mentorRelationshipFromJson(mentorRelationship: MentorRelationship): MentorRelationship {
    return {
        ...new MentorRelationship(),
        ...mentorRelationship,
    }
}

export function newMentorRelationship(isMentor: boolean): MentorRelationship {
    return {
        ...new MentorRelationship(),
        isMentor,
        startDate: getCurrentDateWithoutTime()
    }
}

export const mentorRelationshipIsEnded = (mentorRelationship: MentorRelationship): boolean =>
    !!mentorRelationship.endDate && mentorRelationship.endDate <= getCurrentDateWithoutTime()

export class MentorActivity {
    mentorActivityId: string | null = null
    mentorRelationshipId: string | null = null
    isMentor: boolean = false;
    title: string = "";
    activityDate!: string;
    notes: string = ""
    hours: number | null = null;
}

export const otherPersonRole = (mentorRelationship: MentorRelationship) =>
    mentorRelationship.isMentor ? "mentee" : "mentor"

export const myRole = (mentorRelationship: MentorRelationship) =>
    mentorRelationship.isMentor ? "mentor" : "mentee"

export function mentorActivityFromJson(mentorActivity: MentorActivity): MentorActivity {
    return {
        ...new MentorActivity(),
        ...mentorActivity,
    }
}

export function newMentorActivity(): MentorActivity {
    return {
        ...new MentorActivity(),
        activityDate: getCurrentDateWithoutTime()
    }
}
