import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

class Props {
    title!: string
    hyperlinks!: string | null
}

const hyperlnkRegex = /^(http|https):\/\/[^ "]+$/
export function isUrl(s: string) {
    return hyperlnkRegex.test(s)
}

interface ParsedHyperlink {
    title?: string
    href?: string
}

/*
 * The input is broken up into lines.
 * Any line that's not a URL is assumed to be a title (blank lines are ignored).
 * Wherever a title line immediately precedes an href line, the output combines the 
 * two into a single output entry, otherwise the output contains only one or the other.
 * See unit tests for a better idea.
 */
export function parseHyperlinks(hyperlinks: string): ParsedHyperlink[] {
    return (hyperlinks ?? "")
        .split("\n")
        .map(_.trim)
        .filter(o => o) // remove blank lines
        .reduce<ParsedHyperlink[]>(
            (acc, curr) => {
                if (!isUrl(curr)) {
                    // title precedes href, so a title line will always add a new entry
                    return [...acc, { title: curr }]
                } else if (!acc.length || _.last(acc)?.href) {
                    // if the output is currently empty or the last output line already has an href,
                    // the current line is an href without a title
                    return [...acc, { href: curr }]
                } else {
                    // last output line already contains a title, so add the href
                    return [..._.initial(acc), { ..._.last(acc), href: curr }]
                }
            },
            [])
}

export default function HyperlinkList({ title, hyperlinks }: Props) {
    const hyperlinksList = parseHyperlinks(hyperlinks ?? "")
    if (!hyperlinksList.length) {
        return null
    }
    return (
        <>
            <h5>{title}</h5>
            <ul className="resources-list">
                {hyperlinksList.map((x, i) =>
                    <li key={i}>
                        {x.href
                            ? <a href={x.href}
                                target="_blank"
                                rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                                <span> </span>
                                {x.title ?? _.truncate(x.href, {length:40})}
                            </a>
                            : <span>{x.title}</span>}
                    </li>
                )}
            </ul>
        </>
    )
}
