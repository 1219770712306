import { useState } from "react"
import { useHistory } from "react-router-dom"
import { AccordionContent, AccordionItem, AccordionTitle } from "react-foundation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen } from "@fortawesome/free-solid-svg-icons"
import CupidSlideDown from "../utils/cupid-slidedown"
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons"
import { formatDateWithoutTimeForDisplay } from "../../modules/date-without-time"
import { MentorActivity, MentorRelationship, mentorRelationshipIsEnded } from "../../modules/data-objects/mentor"
import { showConfirm } from "../../modules/show-alert"
import cupidFetch from "../../modules/cupid-fetch"

class Props {
    mentorRelationship!: MentorRelationship
    mentorActivity!: MentorActivity
    reloadActivityList!: () => void
}

export default function MentorActivityRow({ mentorRelationship, mentorActivity, reloadActivityList }: Props) {
    const [expanded, setExpanded] = useState(false)
    const history = useHistory()

    const editClick = (mentorActivityId: string) => {
        history.push(`/mentorrelationships/${mentorRelationship.mentorRelationshipId}/activities/${mentorActivityId}/edit`)
    }

    const deleteClick = async () => {
        if (!await showConfirm(`Delete this activity "${mentorActivity.title}"`)) return
        const result = await cupidFetch(`/api/mentorrelationships/${mentorRelationship.mentorRelationshipId}/activities/${mentorActivity.mentorActivityId}`, "DELETE")
        if (!result) return
        reloadActivityList()
    }

    return <AccordionItem>
        <AccordionTitle onClick={() => setExpanded(x => !x)}>
            {mentorActivity.activityDate} | {mentorActivity.title}
        </AccordionTitle>
        <CupidSlideDown>
            {expanded && <AccordionContent isActive={true}>
                <div className="grid-x">
                    <div className="cell medium-6">
                        <h2>{mentorActivity.title}</h2>
                        <div className="small button-group">
                            {!mentorRelationshipIsEnded(mentorRelationship) && <fieldset>
                                <button onClick={() => editClick(mentorActivity.mentorActivityId!)} className="warning button">
                                    <FontAwesomeIcon icon={faPen} inverse />
                                    <span> Edit</span>
                                </button>
                                <button className="alert button" onClick={deleteClick}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                    <span> Delete</span>
                                </button>
                            </fieldset>}
                        </div>
                    </div>
                    <div className="cell medium-6">
                        <div className="callout secondary">
                            <p><strong>Activity date:</strong> {formatDateWithoutTimeForDisplay(mentorActivity.activityDate)}</p>
                            <p><strong>Hours:</strong> {mentorActivity.hours}</p>
                        </div>
                    </div>
                    {mentorActivity.notes && <div className="cell medium-6 medium-offset-6">
                        <div className="callout warning">
                            <h5><strong>Notes</strong></h5>
                            <p className="textblock">{mentorActivity.notes}</p>
                        </div>
                    </div>}
                </div>
            </AccordionContent>}
        </CupidSlideDown>
    </AccordionItem>
}
