import React, { useCallback, useEffect, useRef, useState } from 'react'
import cupidFetch from "../../modules/cupid-fetch"
import Activity, { activityFromJson } from '../../modules/data-objects/activity'
import ActivityListView from './activity-list-view'
import Subject from '../../modules/data-objects/subject'

class Props {
    queryString!: string
    reloadActivityListCallback!: () => void
    subject!: Subject
}

export default function ActivityList({ queryString, reloadActivityListCallback, subject }: Props) {
    const [activities, setActivities] = useState<Activity[] | null>(null)
    let isMounted = useRef(true);
    const url = `/api/activities${queryString}`

    const loadActivityList = useCallback(
        async () => {
            const result = await cupidFetch<null, Activity[]>(url)
            if (!isMounted.current) return
            if (!result) return
            setActivities(result.map(o => activityFromJson(o)))
        }, [url])

    useEffect(
        () => {
            (async () => {
                await loadActivityList()
            })()
            return () => { isMounted.current = false }
        },
        [subject, url, loadActivityList]
    )

    const reloadActivityList = async () => {
        await loadActivityList()
        reloadActivityListCallback()
    }

    return (
        <ActivityListView
            activities={activities}
            reloadActivityList={reloadActivityList}
            subject={subject} />
    )
}
