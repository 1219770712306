import Subject from '../modules/data-objects/subject'
import { showAlert } from '../modules/show-alert'
import FileUpload, { UploadState } from './utils/file-upload'
import { LoggedInAs } from './utils/logged-in-as'

class Props {
    subject!: Subject
}

export default function ImportCpd({ subject }: Props) {

    const uploadInProgressCallback = (uploadState: UploadState) => {
        if (uploadState === UploadState.Completed) {
            showAlert("Your CPD has been uploaded. It should be processed within 5 working days.\nWe recommend you keep hold of the file until you have verified the import is complete.")
        }
    }

    const getFileUploadUrlCallback = async (): Promise<string | null> => {
        return `/api/activities/import`
    }

    return (
        <div className="grid-container main-content">
            <div className="grid-x">
                <div className="cell medium-12">
                    <h1 className="page-title">Import CPD</h1>
                    <LoggedInAs subject={subject} />
                </div>
            </div>
            <div className="grid-x">
                <div className="cell medium-7 intro-text">
                    <p>To import CPD, download the Excel template file from the link on the right,
                    add one row per CPD entry and then click 'Upload file' below to submit the file to IHEEM.</p>
                    <p>Make sure you have saved and closed the file in Excel before you upload it.</p>
                    <p>The import should normally be processed within 5 working days.
                            You will be notified when it is complete.</p>
                </div>
                <div className="cell medium-4 medium-offset-1">
                    <a className="button" href="assets/cpd-import-template.xlsx" target="_blank">Excel template</a>
                </div>
            </div>
            <div className="grid-x">
                <div className="cell small-4">
                    <FileUpload
                        uploadInProgressCallback={uploadInProgressCallback}
                        getPostUrlCallback={getFileUploadUrlCallback} />
                </div>
            </div>
        </div>
    )
}
