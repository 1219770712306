import { useState } from 'react';
import Subject from '../../modules/data-objects/subject';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DraftActivityList from '../activity-list/draft-activity-list';
import ProgressWidget from '../target-progress/progress-widget';
import { LoggedInAs } from '../utils/logged-in-as';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

class Props {
    subject!: Subject;
}

function _Home({ subject, history }: Props & RouteComponentProps) {
    const [nonce, setNonce] = useState(0)

    const onNewCpdClick = () => {
        history.push("/activities/new");
    }

    const reloadActivityList = () => {
        setNonce(oldValue => oldValue + 1)
    }

    return (
        <>
            <div className="grid-container main-content">
                <div className="grid-x">
                    <div className="cell medium-12">
                        <h1 className="page-title">MyIHEEM CPD</h1>
                        <LoggedInAs subject={subject} />
                    </div>
                </div>
                <div className="grid-x">
                    <div className="cell medium-7 intro-text">
                        <p>Welcome to MyIHEEM CPD, your system for recording your lifelong learning outcomes.</p>
                        <p>MyIHEEM CPD has been developed to enable you to capture both the quantitative and qualitative 
                            areas of your CPD activities in a useful friendly interface. It allows for easy capture of 
                            your reflective learning by answering 4 simple questions, with quick uploading of support
                             evidence. Preloaded IHEEM CPD events will exist where applicable (via session code under new CPD).</p>
                        <p>There is no need to record CPD anywhere else, as MyIHEEM CPD provides you with the unique facility
                             to share your CPD record with other professional bodies for their review (via the grant reviewer access tab).</p>
                        <p>If you have any CPD records in draft status they are shown below. 
                            To see your full record of CPD go to the My CPD tab.</p>
                        <ProgressWidget key={nonce} isSysadmin={subject.isSysadmin()} />
                    </div>
                    <div className="cell medium-4 medium-offset-1">
                        <button className="button" onClick={onNewCpdClick}><FontAwesomeIcon icon={faPlusCircle} size="lg" /> New CPD</button>
                    </div>
                </div>
            </div>
            <div>
                <DraftActivityList reloadActivityList={reloadActivityList} subject={subject} />
            </div>
        </>
    );
}

const Home = withRouter(_Home);
export default Home;
